import React from 'react';
import { BrowserRouter as Router,Redirect } from 'react-router-dom';
import packageJson from "../package.json";
import { Route, Switch } from 'react-router';
import withClearCache from "./ClearCache";
import Connexion from "./Components/Auth/Connexion"
import { DataContext, DataProvider } from "../src/redux/store/GlobalState";
import ModifierPassword from "./Components/Auth/ModifierPassword";
import { BrowserRouter } from 'react-router-dom';
import ResetPassword from './Components/Auth/ResetPassword';
import Acceuil from './Components/Backend/ADMIN/Dashboard/Acceuil';
import Parametre from './Components/Backend/ADMIN/Parametre/Parametre';
import GestionUtilisateur from './Components/Backend/ADMIN/GestionUtilisateurs/GestionUtilisateur';
import GestionJayanter from './Components/Backend/ADMIN/GestionJayanter/GestionJayanter';
import GestionListeProjet from './Components/Backend/ADMIN/GestionListeProjet/GestionListeProjet';
import GestionCategorie from './Components/Backend/ADMIN/GestionListeCategorie/GestionCategorie';
import GestionActualite from './Components/Backend/ADMIN/GestionListeActualite/GestionActualite';
import FrontLogin from './Components/FrontEnd/frontLogin'
import FrontRegister from './Components/FrontEnd/frontRegister'
import FrontHome from './Components/FrontEnd/frontHome'
import FrontProjet from 'Components/FrontEnd/frontProjet/frontProjet';
import Apropos from 'Components/FrontEnd/Apropos/Apropos';
import Contribution from 'Components/FrontEnd/ContributionBarkelou/Contribution';
import ContributionProjet from 'Components/FrontEnd/ContributionProjet/ContributionProjet'
import ActualiteListe from 'Components/FrontEnd/ActualitesListe/ActualiteListe' 
import DetailActu from 'Components/FrontEnd/DetailActu/DetailActu';
import QuiSommesNous from 'Components/FrontEnd/QuiSommesNous/QuiSommesNous';
import ThankYou from 'Components/FrontEnd/ContributionProjet/ThankYou';
import PageError from 'Components/FrontEnd/ContributionProjet/PageError';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
function MainApp() { 
 
 
  return (
    <DataProvider> 
      <BrowserRouter>
        <div className="router-container">
          <Switch>
            {/* ADMIN */}
         {/*  <Route exact path="/admin-connexion"component={Connexion} />
              <Route path="/demande-modification-password" component={ModifierPassword} />
            <Route path="/modifier-mon-mot-de-passe" component={ResetPassword} />
            <Route exact path="/admin/accueil" component={Acceuil} />
            <Route path="/admin/parametre" component={ Parametre} />
            <Route path="/admin/gestion-des-utilisateurs" component={GestionUtilisateur} />
            <Route path="/admin/gestion-des-jayanters" component={GestionJayanter} /> 
            <Route path="/admin/gestion-des-projets" component={GestionListeProjet} />
            <Route path="/admin/domaine-des-projets" component={GestionCategorie} />
            <Route path="/admin/actualites" component={GestionActualite} /> */}
            {/* FRONTEND */}
            {/* <Route exact path="/" component={FrontHome} /> */}
            <Route exact path="/connexion" component={FrontLogin} />
            <Route exact path="/inscription" component={FrontRegister} />
            <Route exact path="/" component={FrontHome} />
            <Route exact path="/projets-en-cours" component={FrontProjet} />
            {/* <Route exact path="/a-propos" component={Apropos} /> */}
            <Route exact path="/a-propos" component={QuiSommesNous} />
            <Route exact path="/contribution" component={Contribution } />
            <Route exact path="/thank-you" component={ThankYou } />
            <Route exact path="/error-transaction" component={PageError } />
            <Route exact path="/contribution-projet/:id" component={ContributionProjet } />
            <Route exact path="/actualite-liste" component={ActualiteListe } />
            <Route exact path="/detail-actualite/:id" component={DetailActu } />
          </Switch>
        </div>
      </BrowserRouter>
      </DataProvider>

  );
}
export default App;
