import React, { useContext, useEffect, useState} from 'react'
import $ from 'jquery'
import FeatherIcon from 'feather-icons-react';

var ModalQrCodeWave = (props) => {
    console.log('my propsos',props);
        return (
            <div className="modal fade open-reset-password-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-card">
                        <div className="row m-b-10">
                            <div className="col-10 itm-center">
                                <p className="">Veuillez scanner le code si vous n'avez pas reçu le sms</p>

                            </div>
                            <div className="col-2">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><FeatherIcon icon="x" /></span>
                                </button>
                            </div>
                        </div>
                        <iframe src={props.htpps} frameborder="0"  toolbar="no" location="no" menubar="no" scrollbars="no" width="100%" height="480"></iframe>
                    </div>
                </div>
            </div>
        );
    }

export default ModalQrCodeWave;