
 const CategorieData = [
    {
        id: 1,
        title: 'Environnement',
    
        
    },
    {
        id: 2,
        title: 'Santé',
      
    },
    {
        id: 3,
        title: 'Watiaye Magal',
       
    },
    {
        id: 4,
        title: 'Communication',
    },
];

export default CategorieData;
;
