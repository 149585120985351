import React, { useContext,useEffect} from 'react'
import './QuiSommesNous.css'
import { DataContext } from '../../../redux/store/GlobalState';
import FrontHeader from '../frontHeader/index';
import FrontFooter from '../frontFooter';

import newLogoTck2 from '../../../assets/img/new-logo-tck-2@2x.png'

var QuiSommesNous = () => {

    const { state } = useContext(DataContext)
    let { users } =state
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className="component-liste-projet">
    <FrontHeader />
      <div className="content-view-start">
           
            <div className="container-tck ">
                <div className="row">
                <div className="col-md-12 mb-2 pb-2">
                    <div className="row">
                        <div className="col-md-1 mb-2 pb-2"></div>
                        <div className="col-md-5 mb-2 pb-2">
                            <div className="py-2 text-center">
                                <h1 className="h3-theme">Qui sommes-nous? </h1>
                            </div>
                            <div className="pb-5 mb-5">
                                <p className="text-paragraph">TOUBA CA KANAMest une association apolitique qui investit sa force, sa richesse,son intelligence au service de CHEIKH AHMADOU BAMBA KHADIM RASSOUL. Une entité, association à but non lucratif crée en 2017 sous le Ndiguel du Khalife General des Mourides Cheikh Sidy Mokhtar MBAKE, ayant comme objectif : le développement de la ville sainte de Touba selon les principes de son fondateur le vénéré Cheikh Ahmadou Bamba. Une vision de la ville sainte déclinée dans son ouvre : Matlabul Fawzayni.</p>
                            </div>
                        </div>
                        <div className="col-md-5 mb-2 pb-2">
                            <div className="pb-5 mb-5">
                                <div>
                                        <img src={newLogoTck2} alt="qsn" className="new-logo-tck-2" />
                                </div>                
                            </div> 
                        </div>
                        <div className="col-md-1 mb-2 pb-2"></div>
                    </div>
                </div>
                </div>
                <div className="row ">
                    <div className="row py-2 my-2 ">
                        <div className="col-md-1 mb-2 pb-2"></div>
                        <div className="col-md-10 col-sm-12 mb-2 pb-2 bloc-detail">
                                <div className="py-2 text-center">
                                <div className="touba-ca-kanam-en-4-dates heading-1-small">
                                <span className="span heading-1-small">Touba ca Kanam en</span>
                                <span className="span1-1 heading-1-small">&nbsp;4</span>&nbsp;
                                <span className="span heading-1-small">dates</span>
                            </div>
                            </div>
                            <div className="col-md-12 col-sm-12 mb-2 pb-2">
                                <h3 className="h3-theme_texte color-theme">
                                    Les debuts</h3>
                                <div className="">
                                <p className="text-paragraph  body-small">Touba Ca Kanam &nbsp; est une association apolitique créée en Août 2016 au domicile de Serigne Ndame Atta MBACKE par des disciples « Talibés » mourides avertis, réfléchis et très conscient de la situation socioéconomique défavorable de la ville sainte de Touba.
            En effet, l’idée originelle est partie d’une émission qui se passait sur un site internet du groupe TOUBA INFOS (dont l’Administrateur se nomme Mr Fallou NDIAYE) à travers laquelle il était traité des thèmes autour de problématiques de développement inhérentes à la cité bénite de Touba.
            Partant de ce constat, une plateforme de discussions et d’échanges a été mise sur place notamment un groupe WhatsApp pour recueillir les idées et les propositions sur les possibles axes de développement de la ville de Touba. Ainsi, ces débats organisés firent l’objet d’une synthèse consignée dans un document..</p>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2 pb-2">
                                <h3 className="h3-theme_texte color-theme">La première assemblée générale constitutive</h3>
                                <div className="">
                                <p className="text-paragraph  body-small">Cependant, au vu de l’importance et la richesse des idées émises par les débatteurs, une assemblée générale constitutive a été convoquée le 26 Août 2016 à 17 heures au domicile de Serigne Ndame Ata Mbacke. L’objectif était de définir clairement un statut juridique nous permettant d’intervenir de manière légale dans le pays en général et dans la ville sainte en particulier. Au terme de cette rencontre, le statut d’Association à but non lucratif fut adopté à l’unanimité par les principaux membres présents.
            Ainsi, un comité directeur composé de 18 personnes a été mis sur pied qui à son tour élit un bureau restreint notamment (un président, un secrétaire général, un trésorier général et des commissaires aux comptes) puis des commissions techniques chargées de la réalisation des objectifs de l’association..</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 mb-2 pb-2">
                                <h3 className="h3-theme_texte color-theme">La délégation de Touba Ca Kanam chez Serigne Sidi Mokhtar MBACKE</h3>
                                <div className="">
                                <p className="text-paragraph  body-small">Cependant, au vu de l’importance et la richesse des idées émises par les débatteurs, une assemblée générale constitutive a été convoquée le 26 Août 2016 à 17 heures au domicile de Serigne Ndame Ata Mbacke. L’objectif était de définir clairement un statut juridique nous permettant d’intervenir de manière légale dans le pays en général et dans la ville sainte en particulier. Au terme de cette rencontre, le statut d’Association à but non lucratif fut adopté à l’unanimité par les principaux membres présents.
            Ainsi, un comité directeur composé de 18 personnes a été mis sur pied qui à son tour élit un bureau restreint notamment (un président, un secrétaire général, un trésorier général et des commissaires aux comptes) puis des commissions techniques chargées de la réalisation des objectifs de l’association.</p>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2 pb-2">
                                <h3 className="h3-theme_texte color-theme">Début des activités</h3>
                                <div className="">
                                <p className="text-paragraph  body-small">C’est à partir de ce moment que « TOUBA CA KANAM » lança une vaste campagne de sensibilisation et de communication pour la souscription des fidèles mourides dont l’attachement frappant au rayonnement de Touba est connu. Ces contributions volontaires (dont un montant minimum et forfaitaire de 1000 F par fidèle fut fixé), collectées à travers un vaste réseau de représentants bénévoles, à travers le Sénégal et la diaspora mouride, se fondent sur un système de ventes de reçus, de transferts électroniques et de virements bancaires, qui ont jusqu'ici permis de financer l’essentiel des activités et projets de l'association.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 mb-2 pb-2"></div>
                    </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 pb-3">
                        <div className="row">
                        <div className="col-md-1 mb-2 pb-2"></div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 pb-3">
                                <div>
                                    <h3 className="h3-theme color-theme">Missions & Objectifs de Touba ca Kanam</h3>
                                    <div className="py-4">
                                        <p className="text-paragraph">Touba Ca Kanam vise le développement socio-économique de Touba. Et c’est de manière inclusive qu’elle compte s’y prendre en impliquant les Mourides, où qu’ils puissent se trouver à travers le monde. 
                                        L’ambition est de mobiliser 02 millions d’entre eux pour une participation financière individuelle et mensuelle de 1000 F/cfa. Ce qui ferait une somme globale de 02 milliards par an. Dans un de ses poèmes «  Matlabul Fawzeini »
                                        en l’occurrence, Cheikhoul Khadim décrit comment Touba sera. 
                                        Avec une multitude de projets qui touchent plusieurs domaines, TCK s’attelle à la tâche avec la bénédiction du Khalife Général des Mourides et invite tout le monde à venir jouer sa partition.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1 mb-2 pb-2"></div>
                        </div>
                    </div>
                </div>
                 
           </div>
           <div className='bloc-detail'>
           <div className="">
                    <div className="text-center py-5">
                        <h3 className="h3-theme color-theme">L’Équipe Touba ca Kanam</h3>
                    </div>
                    
                </div>  
           <div className="row">
           <div className="col-md-1  mb-2 pb-2"></div>
           <div className="row">
                    {
                        users.map((item, index) => {

                            return (
                                <div className="col-md-3  col-sm-12 "  item={item.id} key={index} >
                                    <div  item={item.id} key={index}  className="frame-184_apropos border-1px-mist-gray mx-4 ">
                                    <img className="image-10" src={item.image}  alt="tck"/> 
                                    </div><br></br>
                                    <div className="overlap-group-container_equipe">
                                            <div className="overlap-group13_1_apropo">
                                                <div className="serigne-habibou-falilou-mbacke-9 heading-6-small">{item.name}</div>
                                                <p className="responsable-moral-1-de-touba-ca-kanam-9 caption-small">
                                                    {item.postion}
                                                </p>
                                            </div>
                                        </div>
                                </div>
                            )})
                    }
                </div>  

                <div className="col-md-1 mb-2 pb-2"></div>  
                </div>  
            </div>
            </div>
            <div>
            </div>
      </div>
      <FrontFooter />
         </div>
    )
}

export default QuiSommesNous;