import React, { useContext } from 'react'
import './ProjetItem.css'
import { TimeCircle } from 'react-iconly'
import baseUrl from '../../../Http/backend-base-url';
import moment from 'moment';
import { DataContext } from '../../../redux/store/GlobalState';
import { getDonationOwnProject } from '../../../redux/utils/fetchData';
import ImageLabel from "../../../assets/images/image_label.png";
import { NavLink } from 'react-router-dom';
import backimage from '../../../assets/images/slider.png'

var ProjetItem = (props) => {

    const { state, dispatch } = useContext(DataContext);
    // console.log('mystate',props);
  
    const detailProject = (datas) => {
        console.log(datas);
        dispatch({ type: 'DETAILS_PROJECTS_TO_FRONT', payload: datas})
        dispatch({ type: 'ID_PROJET_CONTRIBUER', payload: datas?.id })
        
    }
    
    return (
        
        <div className="frame-192-2 border-0-5px-mist-gray" >
            {
                props?.item?.project_images?.length > 0 ?
                props?.item?.project_images?.map((image) => {
                    return (
                <div className="frame-192" key={image.id} style={{ backgroundImage: `url(${image.image })` ?? ImageLabel,height:'120px',width:'285px'}}>
                    <div className="rectangle-381-1_banner"></div>
                </div> )
                })
                :
                <div className="frame-192" key={props.item.id} style={{backgroundImage: `url(${ImageLabel })`}}>
                    <div className="rectangle-381-1_banner"></div>
                </div>
            }
            {
                props?.item?
                props?.item?.domains.map((domaine) => {
                    return (
                        <div className="infrastucture caption-large"  key={'domains' + domaine.id}>{domaine.title}</div>
                    )
                })
                :
                ''
            }
                <p className="construction-de-la-route-de-ndamatou heading-5-small">{props?.item?.title}</p>
                <div className="flex-row-14 caption-small-semi-bold">
               <div className="x2000000-fcfa-collect_item">{props?.item?.estimated_budget} FCFA </div>
                  {/* <div className="percent_item">{Math.ceil(props?.item?.percentage_budget)}%</div> */}
                </div>
                <div className="group-14">
               {/*   <div className="progress">
               <div
                    class="progress-bar progress-bar-tab"
                    role="progressbar"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: Math.ceil(props?.item?.percentage_budget) + "%" }}
                ></div> 
                </div>*/}
            </div>
            <div className="button-3">
                <div className="button-filledmxs-fal">
                <NavLink className="button inter-semi-bold-white-16px" to={`/contribution-projet/${props?.item?.id}`}>
                <div className="button-4 opensans-semi-bold-white-12px"  >Contribuer</div>
                </NavLink>
                </div>
            </div>  
            {/* <Button2 /> */}
        </div>
    )
}

export default ProjetItem;