import React, { useContext, useEffect, useState} from 'react'
import './Contribution.css'
import './style.css'
import FrontHeader from '../frontHeader/frontHeader'
import IMG from "../../../assets/img1/group-1710@2x.svg"
import FeatherIcon from 'feather-icons-react';
import Mobile from '../../../assets/img1/img/ant-design-mobile-filled@2x.svg'
import Carte from '../../../assets/img1/img/bi-credit-card-2-back-fill@2x.svg'
import OM from '../../../assets/img1/img/image-4@2x.png'
import Wave from '../../../assets/img1/img/image-3@2x.png'
import Free from '../../../assets/img1/img/image-5@2x.png'
import Kpay from '../../../assets/img1/img/Kpay.png'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import axios from 'axios';
import api from 'Http/backend-base-url';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FrontFooter from '../frontFooter/frontFooter';
import baseUrlFront from '../../../Http/frontend-base-url'
import $ from 'jquery'
import ModalQrCodeWave from './ModalQrCodeWave';

var Contribution = (props) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])  
    var validationSchema = Yup.object().shape({
        amount: Yup.bool()
        .oneOf([true],'Ce champ est obligatoire'),
        // phone: Yup.string().required('Ce champ est obligatoire'),
        provider: Yup.bool()
        .oneOf([true],'Ce champ est obligatoire'),
        type: Yup.bool()
        .oneOf([true],'Ce champ est obligatoire'),
        prenom_nom: Yup.string().required('Ce champ est obligatoire'),
        email: Yup.string().email('veuillez mettre un email valide').required('Ce champ est obligatoire'),
        ville: Yup.string().required('Ce champ est obligatoire'),      
        phone: Yup.string()
        .required("required")
        .matches(phoneRegExp, 'votre numero de telephone est invalide')
        .min(9, "numero trop court")
        .max(15, "numero trop long"),
        })
      const formOptions = { resolver: yupResolver(validationSchema) };
      const [InProgress, setInProgress] = useState(false);

      const token ='6786d66e2bd5d628ee90b9d980e671f2904a9f6d';
      const requestConfig = {
          headers: {
              Authorization: `Token ${token}`,
          },
      }
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;
    const [donate, setDonate] = useState('ponctuel')
    const [amount, setAmount] = useState()
    const [http, setHttp] = useState()
    const [provider, setProvider] = useState()
    const [type, setType] = useState()
    const [paiement, setPaiement] = useState()

    const handleChangeDonate = (e) => {
        let value =  e.target.value
        setDonate(value)
        console.log('recuepration',value);
    }
    var history = useHistory()

    const handleChangeAmount = (e) => {
        let value =  e.target.value
        setAmount( e.target.value)
        console.log('recuepration',value);
    }
    const handleChangeProvider = (e) => {
        let value =  e.target.value
        setProvider( e.target.value)
        console.log('recuepration',value);
    }
    const handleChangePaiement = (e) => {
        let value =  e.target.value
        setPaiement( e.target.value)
        console.log('recuepration',value);
    }
    var  https ='';
    const onSubmitContributionProject = async (data) => {
            console.log(data,donate,paiement);
            
              // setInProgress(true)
              try {
                var formData2 = new  FormData();
                formData2.append('type',donate);
                    if(amount =='other_motant'){
                        formData2.append('amount', data.amount1)
                    }else{
                        formData2.append('amount', amount)
                    }
                formData2.append('phone', "221"+data.phone)
                formData2.append('firstName', data.prenom_nom)
                formData2.append('lastName', data.prenom_nom)
                formData2.append('emailAddress', data.email)
                formData2.append('address', data.ville)
                formData2.append('city', data.ville)
                formData2.append('callback_url', baseUrlFront)
                var formData = new FormData();
                    formData.append('type', donate)
                    if(amount =='other_motant'){
                        formData.append('amount', data.amount1)
                    }else{
                        formData.append('amount', amount)
                    }
                    formData.append('provider', provider)
                    formData.append('phone', data.phone)
                    formData.append('firstName', data.prenom_nom)
                    formData.append('lastName', data.prenom_nom)
                    formData.append('emailAddress', data.email)
                    formData.append('address', data.ville)
                    formData.append('city', data.ville)
                    formData.append('callback_url', baseUrlFront)
                      setInProgress(true)
                      // axios.post('https://tckapi.sen-gt.com/api/payment/mercant/', formData).then((res) => {
                  if(paiement=="card_paiement"){
                      axios.post(api + 'api/payment/card/', formData).then((res) => {  
                          console.log('paimemme card',res.data);
                          if(res.data === 200 | 201){
                            Swal.fire({
                                position: 'center',
                                icon: 'info',
                                html: " Nous allons vous redirigez vers la page de paiment! Jëf Jël !",
                                showConfirmButton: true,
                                iconColor: '#1f4c27',
                                confirmButtonColor: '#1f4c27'
                              })
                            
                              setTimeout(() => {
                                window.location.href=res.data.url
                            }, 3000);
                          }
                          
                              // setInProgress(false);
                          // reset()
                      }).catch((error) => {
                          console.log("ereurrrr",error.response);
      
                        if (error.response.status === 500) {
                          Swal.fire({
                            position: 'center',
                            icon: 'error',
                            html: "Erreur! Veuillez réessayer plus tard!",
                            showConfirmButton: true,
                            iconColor: '#f44336',
                            confirmButtonColor: '#f44336'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setInProgress(false);
      
                            }
                          })
                        }
                        if (error.response.status === 400) {
                          Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: ""+error.response.data.message +"",
                            showConfirmButton: true,
                            iconColor: '#f44336',
                            confirmButtonColor: '#f44336'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setInProgress(false);
      
                            }
                          })
                          
                        }
                        if (error.response.status === 403) {
                            Swal.fire({
                              position: 'center',
                              icon: 'error',
                              html: "Authentification neccesaire",
                              showConfirmButton: true,
                              iconColor: '#f44336',
                              confirmButtonColor: '#f44336'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setInProgress(false);
        
                              }
                            })
                            
                          }
                      }) 
                  }else{
                        if (provider==="PMKpay") {
                            axios.post(api + 'api/payment/kpay/init/', formData2).then((res) => {  
                                console.log('paimemme kpay',res);
                                if(res.status === 201){
                                    Swal.fire({
                                        title: 'Veuillez renseigner le code OTP reçu',
                                        input: 'text',
                                        //html: "<input type='submit'  />",
                                        inputAttributes: {
                                          autocapitalize: 'off',
                                          width: '30',
                                        },
                                        denyButtonText: 'Renvoyer le code OTP',
                                        denyButtonColor: `#1f4c27`,
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        cancelButtonText: 'Annuler' ,
                                        confirmButtonText: 'Envoyer',
                                        confirmButtonColor: `#1f4c27`,
                                        showLoaderOnConfirm: true,
                                        preDeny: (ref_com) => { return axios.post(api + 'api/payment/kpay/code/resend/', {ref_com: res.data.correlationReference}).then(response => {
                                          console.log("response",response)
                                          Swal.fire({
                                            title: 'Veuillez renseigner le code OTP reçu',
                                            input: 'text',
                                            //html: "<input type='submit'  />",
                                            inputAttributes: {
                                              autocapitalize: 'off',
                                              width: '30',
                                            },
                                            denyButtonText: 'Renvoyer le code OTP',
                                            denyButtonColor: `#1f4c27`,
                                            showDenyButton: true,
                                            showCancelButton: true,
                                            cancelButtonText: 'Annuler' ,
                                            confirmButtonText: 'Envoyer',
                                            confirmButtonColor: `#1f4c27`,
                                            showLoaderOnConfirm: true,
                                            preConfirm: (opt) => {
                                              return axios.post(api + 'api/payment/kpay/confirm/', {
                                                otp:opt,
                                                kpayReference:res.data.kpayReference,
                                                customerPhoneNumber:res.data.customer.phoneNumber
                                              })
                                                .then(response => {
                                                    console.log("response",response)
                                                  if (response.status !=  201) {
                                                    Swal.fire({
                                                        position: 'center',
                                                        icon: 'error',
                                                        html: ""+response.data.message +"",
                                                        showConfirmButton: true,
                                                        iconColor: '#f44336',
                                                        confirmButtonColor: '#f44336'
                                                      }).then((result) => {
                                                        if (result.isConfirmed) {
                                                          setInProgress(false);
                                  
                                                        }
                                                      })
                                                  }else if(response.status === 200 | 201){
                                                    Swal.fire({
                                                          
                                                          position: 'center',
                                                          icon: 'success',
                                                          title: "Merci",
                                                          html: ""+response.data.message +"",
                                                          showConfirmButton: true,
                                                          confirmButtonColor: `#1f4c27`,
                                                          cancelButtonColor: "#e89702",
                                                          iconColor: '#1f4c27',
                                                        })
                                                      
                                                  }
                                                  //return JSON.stringify(response)
                                                })
                                                // .catch(error => {
                                                //   Swal.showValidationMessage(
                                                //     `Request failed: ${error}`
                                                //   )
                                                // })
                                            },
                                            allowOutsideClick: () => !Swal.isLoading()
                                          })
                                        //return JSON.stringify(response)
                                      }) },
                                        preConfirm: (opt) => {
                                          return axios.post(api + 'api/payment/kpay/confirm/', {
                                            otp:opt,
                                            kpayReference:res.data.kpayReference,
                                            customerPhoneNumber:res.data.customer.phoneNumber
                                          })
                                            .then(response => {
                                                console.log("response",response)
                                              if (response.status !=  201) {
                                                Swal.fire({
                                                    position: 'center',
                                                    icon: 'error',
                                                    html: ""+response.data.message +"",
                                                    showConfirmButton: true,
                                                    iconColor: '#f44336',
                                                    confirmButtonColor: '#f44336'
                                                  }).then((result) => {
                                                    if (result.isConfirmed) {
                                                      setInProgress(false);
                              
                                                    }
                                                  })
                                              }else if(response.status === 200 | 201){
                                                Swal.fire({
                                                      
                                                      position: 'center',
                                                      icon: 'success',
                                                      title: "Merci",
                                                      html: ""+response.data.message +"",
                                                      showConfirmButton: true,
                                                      confirmButtonColor: `#1f4c27`,
                                                      cancelButtonColor: "#e89702",
                                                      iconColor: '#1f4c27',
                                                    })
                                                  
                                              }
                                              //return JSON.stringify(response)
                                            })
                                            // .catch(error => {
                                            //   Swal.showValidationMessage(
                                            //     `Request failed: ${error}`
                                            //   )
                                            // })
                                        },
                                        allowOutsideClick: () => !Swal.isLoading()
                                      })
                                //     setTimeout(() => {
                                //       window.location.href=res.data.url
                                //   }, 3000);
                                }
                                // else if(res.status === 400 ){
                                //     Swal.fire({
                                //         position: 'center',
                                //         icon: 'error',
                                //         title: "salut",
                                //         showConfirmButton: true,
                                //         iconColor: '#f44336',
                                //         confirmButtonColor: '#f44336'
                                //       })
                                // }
                                
                                     setInProgress(false);
                                 reset()
                            }).catch((error) => {
                                console.log(error.response);
            
                              if (error.response.status === 500) {
                                Swal.fire({
                                  position: 'center',
                                  icon: 'error',
                                  html: "Erreur! Veuillez réessayer plus tard!",
                                  showConfirmButton: true,
                                  iconColor: '#f44336',
                                  confirmButtonColor: '#f44336'
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setInProgress(false);
            
                                  }
                                })
                              }
                              if (error.response.status === 400) {
                                var  errortitle = "";
                                if(error.response.data.exception === "Receiver is not registered "){
                                     errortitle = "Ce numéro n'est pas associé à un compte KPay."
                                    }
                                Swal.fire({
                                  position: 'center',
                                  icon: 'error',
                                  title: ""+errortitle +"",
                                  showConfirmButton: true,
                                  iconColor: '#f44336',
                                  confirmButtonColor: '#f44336'
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    setInProgress(false);
            
                                  }
                                })
                                
                              }
                              if (error.response.status === 403) {
                                  Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    html: "Authentification neccesaire",
                                    showConfirmButton: true,
                                    iconColor: '#f44336',
                                    confirmButtonColor: '#f44336'
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      setInProgress(false);
              
                                    }
                                  })
                                  
                                }
                            })
                            
                            
                        } else { axios.post(api + 'api/payment/mercant/', formData).then((res) => {  

                            console.log('messa',res)
                           if (res.status==201) {
                            if(provider ==="PMOrangeMoney"){
                             Swal.fire({
                               title: "Code d\'autorisation", 
                               html:"<img src=data:image/png;base64,"+res.data.qrCode+" alt='Red dot' />",                    
                               //   showDenyButton: true,
                                 confirmButtonText: `OK`,
                               //   denyButtonText: `Annuler`,
                                 confirmButtonColor: `#1f4c27`,
                                 cancelButtonColor: "#e89702",
                                 iconColor: '#1f4c27',
                                 allowOutsideClick: false
         
         
                             })
                            //  .then(async (result) => {
                             // console.log(result);
                             /* Read more about isConfirmed, isDenied below */
                                //  if (result.isConfirmed) {
                                   
                                 setInProgress(false);
                                 reset()
                              //  }
                               //   else if (result.isDenied) {
                               //       Swal.fire({
                               //           title: 'Hoo dommage',
                               //           icon: 'info',
                               //           iconColor: '#1f4c27',
                               //           confirmButtonColor: '#1f4c27',
                               //           allowOutsideClick: false
                               //       })
                               //   }
                                 
                            //  })
                                                         
                             } else if(provider==="PMWave"){
                              Swal.fire({
                                  position: 'center',
                                  icon: 'info',
                                  html: " Nous allons vous redirigez vers la page pour scanner le Qrcode !",
                                  showConfirmButton: true,
                                  iconColor: '#034f81',
                                  confirmButtonColor: '#034f81'
                                })
                                setTimeout(() => {
                                  // $('.mdpOublie').click();
                                  //  setHttp(res.data.url)
                                  // console.log('http',http)
                                  //  navigate('/scanner-qrcode')
      
                                   window.location.href=res.data.url
                                  setInProgress(false)
      
                              }, 2000);
                              //  $('.mdpOublie').click();
                              // setHttp(res.data.url)
                              // console.log('http',http)
                            }
                            else{
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Merci!',
                                showConfirmButton: true,
                                iconColor: '#1f4c27',
                                confirmButtonColor: '#1f4c27',
                                allowOutsideClick: false

                            })

                        } }
                         // setInProgress(false);
                             // reset()
                         }).catch((error) => {
                             console.log(error.response);
         
                           if (error.response.status === 500) {
                             Swal.fire({
                               position: 'center',
                               icon: 'error',
                               title: ""+error.response.data.message +"",
                               showConfirmButton: true,
                               iconColor: '#f44336',
                               confirmButtonColor: '#f44336'
                             }).then((result) => {
                               if (result.isConfirmed) {
                                 setInProgress(false);
         
                               }
                             })
                           }
                           if (error.response.status === 400) {
                             Swal.fire({
                               position: 'center',
                               icon: 'error',
                               html: ""+error.response.data.message +"",
                               showConfirmButton: true,
                               iconColor: '#f44336',
                               confirmButtonColor: '#f44336'
                             }).then((result) => {
                               if (result.isConfirmed) {
                                 setInProgress(false);
         
                               }
                             })
                             
                           }
                           if (error.response.status === 403) {
                               Swal.fire({
                                 position: 'center',
                                 icon: 'error',
                                 html: "Veuillez réessayer",
                                 showConfirmButton: true,
                                 iconColor: '#f44336',
                                 confirmButtonColor: '#f44336'
                               }).then((result) => {
                                 if (result.isConfirmed) {
                                   setInProgress(false);
           
                                 }
                               })
                               
                             }
                         })
                            
                        }
                     
                  }
                     
                  
                }catch (error) {
                  console.log("error.response.status", error.response);
                  Swal.fire({
                      position: 'center',
                      icon: 'error',
                      html: 'Erreur...',
                      showConfirmButton: true,
                      iconColor: '#f44336',
                      confirmButtonColor: '#f44336'
                  }).then((result) => {
                      if (result.isConfirmed) {
                          setInProgress(false);
                          // reset()
                      }
                  })
              }
      }

    return (
        <div >
        <FrontHeader />

            <section className="container-tck ">
            <div class="row">
                <div class="col-md-1 col-lg-1 col-sm-2"></div>
                    <div class="col-md-10 col-lg-10 col-sm-12">
                        <div class="row  my-5 py-3-md py-5 py-1-sm bloc-detail my_row_radius">
                            <div class="col-md-12 col-lg-8 col-sm-12 py-3 py-3-lg py-1-sm">
                                <h1 className="laaj-lu-u-man-def-ko-fu-u-xam2 display">Laaj lu ñu man,<br />Def ko fu ñu xam.</h1>
                                <p className="jaamul-ylla-ci-jaam2 caption-large">Jaamul Yàlla ci jaami Yàlla yi, bul xeeb dara bul yéem dara.</p>  
                            </div>
                            <div class="col-md-12 col-lg-4 py-3  py-3-lg py-1-sm col-sm-12">
                                <img className="group-1710_other" src={IMG} alt="tck"/>
                            </div>
                            <form  onSubmit={handleSubmit(onSubmitContributionProject)} id="contriibution-form">
                                <div class="row  my-2 py-2-md py-2 py-1-sm">
                                    <div class="col-md-12 col-lg-4 col-sm-12 py-3 py-3-lg py-1-sm">
                                        <div className="frame-299_mobile  border-1px-grey-contour">
                                            <div className="component-8 border-1px-grey-contour" style={{display:'none'}}>
                                                <input  type="radio" name="type" id="option-1" checked={donate==='ponctuel'} value="ponctuel"  onChange={handleChangeDonate} />
                                                <input type="radio" name="type" id="option-2" checked={donate==='mensuel'}  value="mensuel" onChange={handleChangeDonate} />
                                                <label for="option-1" className="option option-1">
                                                    <div className="frame-299-1">
                                                        <div className="je-donne-une-fois caption-large option option-1">Je donne une fois</div>
                                                    </div> 
                                                </label>  
                                                <label for="option-2" className="option option-2">
                                                    <div className="frame-300 border-1px-grey-contour">
                                                        <div className="je-donne-tous-les-mois caption-large option option-2">Je donne tous les mois</div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="component-9">
                                                <input type="radio" name="amount" id="x1000-1" value="1000"  onChange={handleChangeAmount} />
                                                <input type="radio" name="amount" id="x1000-2" value="2000" onChange={handleChangeAmount}/>
                                                <input type="radio" name="amount" id="x1000-other" value="other_motant" onChange={handleChangeAmount}/>

                                                <label for="x1000-1" className="option x1000-1">
                                                <div className="x1000-francs border-1px-grey-contour">
                                                    <div className="address-4 caption-small-semi-bold ">1000 Francs</div>
                                                </div>
                                                </label>
                                                <label for="x1000-2" className="option x1000-2">
                                                <div className="x1000-francs-1 border-1px-grey-contour">
                                                    <div className="x1000-francs-2 caption-small-semi-bold">2000 Francs</div>
                                                </div>
                                                </label>
                                            </div> 
                                            <div className="component-9">
                                                <input type="radio" name="amount" id="x1000-5" value="5000"  onChange={handleChangeAmount} />
                                                <input type="radio" name="amount" id="x1000-10" value="10000" onChange={handleChangeAmount}/>
                                                <label for="x1000-5" className="option x1000-5">
                                                <div className="x1000-francs border-1px-grey-contour">
                                                    <div className="address-4 caption-small-semi-bold ">5.000 Francs</div>
                                                </div>
                                                </label>
                                                <label for="x1000-10" className="option x1000-10">
                                                <div className="x1000-francs-1 border-1px-grey-contour">
                                                    <div className="x1000-francs-2 caption-small-semi-bold">10.000 Francs</div>
                                                </div>
                                                </label>
                                               
                                            </div>
                                            
                                            <div className="component-9">
                                                <label for="x1000-other" className="option x1000-other">
                                                    <div className="x1000-francs-2 border-1px-grey-contour">
                                                        <div className="x1000-francs-2 caption-small-semi-bold">Autre Montant</div>
                                                    </div>
                                                </label>
                                            </div>
                                            {amount =="other_motant" &&  
                                            <div className="input-group my-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text span-input-icon" id="basic-addon1">
                                                        <FeatherIcon className="icon-input" icon="credit-card" />
                                                    </span>
                                                        <input name="amount" id="montant_other" type="number" min="10"  className="form-control input-with-icon" 
                                                        style={{fontSize:"14px"}} placeholder="autre montant" 
                                                        {...register('amount1')}/>
                                                        {/* {errors?.ville && (
                                                            <div className="alert alert-danger easypm-alert-danger">
                                                                {' '}
                                                                {errors?.ville?.message}{' '}
                                                            </div>
                                                        )} */}
                                                </div>
                                            </div> 
                                            }
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-4 py-3 col-sm-12  py-3-lg py-1-sm col-sm-12">
                                        <div className="frame-302_mobile border-1px-grey-contour">
                                            <div className="input-group my-1">
                                            {errors?.prenom_nom && (
                                                    <p style={{color:"red",fontSize:"10px",marginLeft:"4px"}}>
                                                        {' '}
                                                        {errors?.prenom_nom?.message}{' '}
                                                    </p>
                                                )} 
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text span-input-icon" id="basic-addon1">
                                                        <FeatherIcon className="icon-input" icon="users" />
                                                    </span>
                                                        <input name="prenom_nom" id="name" type="text" className="form-control input-with-icon"  style={{fontSize:"14px"}} 
                                                        placeholder="Prenom et Nom" 
                                                        {...register('prenom_nom')}
                                                        />
                                                    
                                                                    {/* {errors?.prenom_nom && (
                                                                        <div className="alert alert-danger easypm-alert-danger">
                                                                            {' '}
                                                                            {errors?.prenom_nom?.message}{' '}
                                                                        </div>
                                                                    )} */}
                                                </div>
                                            </div> 
                                            <div className="input-group my-1">
                                            {errors?.email && (
                                                    <p style={{color:"red",fontSize:"10px",marginLeft:"4px"}}>
                                                        {' '}
                                                        {errors?.email?.message}{' '}
                                                    </p>
                                                )} 
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text span-input-icon" id="basic-addon1">
                                                        <FeatherIcon className="icon-input" icon="mail" />
                                                    </span>
                                                        <input name="email" id="email" type="email" className="form-control input-with-icon"  
                                                        style={{fontSize:"14px"}} placeholder="Votre  email"
                                                        {...register('email')}/>
                                                        {/* {errors?.email && (
                                                            <div className="alert alert-danger easypm-alert-danger">
                                                                {' '}
                                                                {errors?.email?.message}{' '}
                                                            </div>
                                                        )} */}
                                                </div>
                                            </div>  
                                            <div className="input-group my-1">
                                            {errors?.ville && (
                                                    <p style={{color:"red",fontSize:"10px",marginLeft:"4px"}}>
                                                        {' '}
                                                        {errors?.ville?.message}{' '}
                                                    </p>
                                                )} 
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text span-input-icon" id="basic-addon1">
                                                        <FeatherIcon className="icon-input" icon="map" />
                                                    </span>
                                                        <input name="ville" id="ville" type="text" className="form-control input-with-icon" 
                                                        style={{fontSize:"14px"}} placeholder="Votre Ville" 
                                                        {...register('ville')}/>
                                                        {/* {errors?.ville && (
                                                            <div className="alert alert-danger easypm-alert-danger">
                                                                {' '}
                                                                {errors?.ville?.message}{' '}
                                                            </div>
                                                        )} */}
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-4 py-3 col-sm-12  py-3-lg py-1-sm col-sm-12">
                                    <div className="frame-302-2_mobile border-1px-grey-contour">
                                        <div className="component-container">
                                            <input type="radio" name="carte" id="mobile_paiement" value="mobile_paiement"  onChange={handleChangePaiement}  />
                                            <input type="radio" name="carte" id="card_paiement" value="card_paiement"   onChange={handleChangePaiement} />
                                            <label for="mobile_paiement" className="option mobile_paiement">
                                            <div className="component-10">
                                                <img className="ant-designmobile-filled" src={Mobile} alt="mobile" />
                                                <div className="mobile-money caption-small-semi-bold">Mobile money</div>
                                                <div className="rectangle-356"></div>
                                            </div>
                                            </label>
                                            <label for="card_paiement" className="option card_paiement">
                                            <div className="component-10">
                                                <img className="bicredit-card-2-back-fill" src={Carte} alt="carte bancaire"/>
                                                <div className="carte-bancaire caption-small">Carte bancaire</div>
                                                <div className="rectangle-356"></div>
                                            </div>
                                            </label>
                                        </div>
                                        <div className="input-bar-2"> 
                                            <div className="input-group my-4">
                                            {errors?.phone && (
                                                <p style={{color:"red",fontSize:"10px"}}>
                                                    {' '}
                                                    {errors?.phone?.message}{' '}
                                                </p>
                                            )} 
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text span-input-icon" id="basic-addon1">
                                                        <FeatherIcon className="icon-input" icon="phone" />
                                                    </span>
                                                        <input name="phone" id="phone" type="text" min="0"  style={{fontSize:"15px"}} className="form-control input-with-icon" placeholder="ex:77800..." 
                                                    {...register('phone')} />
                                                </div>
                                            </div> 
                                        </div>
                                        {paiement=="card_paiement" &&  
                                        <div>
                                            <div className="input-bar-2">
                                            
                                            </div>
                                            <div className="contribuer-bouton-2-1">
                                                {!InProgress ? (
                                                    <button className="contribuer-1 caption-small-semi-bold"  type="submit">Cliquez pour Contribuer</button>
                                                ) : (
                                                    <span className="contribuer-1 crud-submit-btn12">En cours&nbsp;
                                                        <i className="fas fa-spin fa-spinner"></i>
                                                    </span>

                                                )}                        
                                            </div>
                                        </div>
                                        } 
                                    
                                        
                                        <div className="frame-container-1">
                                            <input type="radio" name="provider" id="Wave" value="PMWave" onChange={handleChangeProvider} />
                                            <input type="radio" name="provider" id="OM" value="PMOrangeMoney" onChange={handleChangeProvider}/>
                                            <input type="radio" name="provider" id="Kpay" value="PMKpay" onChange={handleChangeProvider}/>
                                            <input type="radio" name="provider" id="FreeMoney" value="PMFreeMoney" onChange={handleChangeProvider} />
                                            <input type="radio" name="provider" id="visa" value="PMWizall" onChange={handleChangeProvider}/>
                                            <label for="Wave" class="option Wave">
                                            <div className="frame-27 border-0-5px-grey-tck-contour">
                                            <img className="image-3" src={Wave} alt="Wave" />
                                            </div>
                                            </label><br></br>
                                            <label for="OM" className="option OM">
                                            <div className="frame-28 border-0-5px-grey-tck-contour">
                                                <img className="image-4" src={OM} alt="OM"/>
                                            </div>
                                            </label><br></br>
                                            <label for="Kpay" class="option Kpay">
                                            <div className="frame-27 border-0-5px-grey-tck-contour">
                                            <img className="imageKpay" src={Kpay} alt="Kpay" />
                                            </div>
                                            </label><br></br>
                                        
                                        </div>
                                        <div className="contribuer-bouton-2-1">
                                        {!InProgress ? (
                                                <button className="contribuer-1 caption-small-semi-bold"  type="submit">Contribuer</button>
                                            ) : (
                                                <span className="contribuer-1 crud-submit-btn12">En cours&nbsp;
                                                    <i className="fas fa-spin fa-spinner"></i>
                                                </span>

                                            )}
                                        </div>
                                    </div>
                                    </div>
                                </div> 
                            </form>
                        </div>
                    </div>
                <div class="col-md-1 col-lg-1 col-sm-2"></div>
                <div className="dis-flex justify-content-end m-b-40">
                    <button onClick={(e) => e.preventDefault()} type="button" className="mdpOublie" data-toggle="modal" data-target=".open-reset-password-modal"></button>
                </div>

                <ModalQrCodeWave htpps={http}/>
            </div>
            </section>
            <FrontFooter  />
        </div>

    // </div> 
    )
}

export default Contribution;
