import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import { ToastContainer,toast  } from 'react-toastify';
// import isAuthenticate from './../modules/Secure/Secure'
import ResetPasswordModal from './ResetPasswordModal'
import EmptyHeader from './EmptyHeader'
import axios from 'axios';
import api from "./../../Http/backend-base-url";
import Swal from "sweetalert2";


export default class Connexion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            errors: {},
            isLoginInProgress: false,
            isSuccessLogin: false,
            isErrorLogin: false,
            is_empty_username: false,
            is_empty_password: false,
            isInvalidCredential: false,
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Token ${window.localStorage.getItem('userToken')}` }
            },
            userID: window.localStorage.getItem('userID'),
            userType: window.localStorage.getItem('userType'),

        }

        this.handleChange = this.handleChange.bind(this)
        this.onSubmitLogin = this.onSubmitLogin.bind(this)
    }

    componentDidMount() {
        // this.onTestUserConnectedStatus()
    }

    onTestUserConnectedStatus = () => {
        // var isNotConnected = isAuthenticate()
        // var userType = this.state.userType
        // var isFirstConnexion = window.localStorage.getItem('userFirstConnexion')

        // if (!isNotConnected && userType === 'student') {
        //     if (isFirstConnexion === true) {
        //         window.location = '/welcome'
        //     } else {
        //         window.location = '/tous-les-modules'
        //     }
        // } else if (!isNotConnected && userType === 'admin') {
        //     window.location = '/tableau-de-bord'
        // }
    }

    handleChange(e) {
        let fields = this.state.fields
        fields[e.target.name] = e.target.value

        if(e.target.name === 'username'){
            fields[e.target.name] = e.target.value;
        }

        this.setState({
            fields: fields
        })
        console.log(fields)
    }

    onValidateLoginForm() {
        let fields = this.state.fields
        let errors = this.state.errors
        var isValidForm = true

        if (!fields['username']) {
            isValidForm = false
            this.setState({
                is_empty_username: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_username: false
                })
            }, 5000);

            errors['username'] = 'Ce champ est obligatoire'
        }
        if (!fields['password']) {
            isValidForm = false
            this.setState({
                is_empty_password: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_password: false
                })
            }, 5000);

            errors['password'] = 'Ce champ est obligatoire'
        }

        this.setState({
            errors: errors
        })

        return isValidForm;
    }

    onSubmitLogin(e) {
        e.preventDefault()
        this.setState({
            isLoginInProgress: true
        })

        setTimeout(() => {
            this.setState({
                isLoginInProgress: false
            })
            // window.location = 'admin/accueil'
        }, 5000);

        if (this.onValidateLoginForm()) {
            this.setState({
                isLoginInProgress: true
            })
            // var api = "http://restapi.adequateshop.com/api/authaccount/login"
            // "email":"Developer5@gmail.com",
            // "password":123456
             var api1 = "http://212.129.25.133:8585"
            var data = this.state.fields
            var fd = new FormData()
            fd.append('username', data.username)
            fd.append('password', data.password)

            // return console.log(data.email.toLowerCase())

            axios.post('http://212.129.25.133:8585/login/', fd)
                .then(response => {
                    console.log("token",response)
                    if (response.status === 200) {
                       
                          
                    // Swal.fire({
                    //     position: 'center',
                    //     icon: 'success',
                    //     title: 'Connexion réussie! ',
                    //     showConfirmButton: true,
                    //     iconColor: '#068923',
                    //     confirmButtonColor: '#068923'
                    //   }).then((result) => {
                    //     if (result.isConfirmed) {
                    //     window.location = '/admin/accueil'
                    //     }
                    //   })
                            var token = response.data.token
                            window.localStorage.setItem('userToken', token)
                            let requestConfig = {
                                headers: { Authorization: `Token ${token}` }
                            }
                            this.onGetConnectedUserInfos(requestConfig)
                    } 
                    // else if (response.data.status === 400) {
                    //     Swal.fire({
                    //         icon: "error",
                    //         title: "Vos identifiants de connexion ne sont pas valides !",
                    //         iconColor: '#068923',
                    //         showCancelButton: false,
                    //         confirmButtonColor: '#068923',
                    //         cancelButtonColor: '#d33',
                    //         confirmButtonText: 'OK',
                    //         showConfirmButton: true,
                    //         allowOutsideClick: false,
                    //         timer: 3000,
                    //     })
                    //     this.setState({
                    //         isInvalidCredential: true
                    //     })

                    //     setTimeout(() => {
                    //         this.setState({
                    //             isInvalidCredential: false
                    //         })
                    //     }, 5000);
                    // }
                })
                .catch(error => {
                    console.log('ere',error)
                    if(error.response.status == 400){
                        Swal.fire({
                            icon: "error",
                            title: "Vos identifiants de connexion ne sont pas valides !",
                            iconColor: '#068923',
                            showCancelButton: false,
                            confirmButtonColor: '#068923',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            timer: 3000,
                        })
                        this.setState({
                            isInvalidCredential: true
                        })

                        setTimeout(() => {
                            this.setState({
                                isInvalidCredential: false,
                                isLoginInProgress: false

                            })
                        }, 3000);
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: "Une erreur est survenue. Veuillez réessayer plus tard.!",
                            iconColor: '#068923',
                            showCancelButton: false,
                            confirmButtonColor: '#068923',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            timer: 3000,
                        })
                        this.setState({
                            isErrorLogin: true,
                            isLoginInProgress: false
                        })
    
                        setTimeout(() => {
                            this.setState({
                                isErrorLogin: false
                            })
                        }, 3000);
                    }
                  
                })
        }
    }

    onGetConnectedUserInfos = (config) => {
        var api_dj ="http://195.154.57.83:8000/dj_rest_auth/user/"
        axios.get(api_dj,config)
            .then(response => {
                console.log('memem',response.status)
                this.setState({
                    isLoginInProgress: false
                })
                if (response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Connexion réussie! ',
                        showConfirmButton: true,
                        iconColor: '#068923',
                        confirmButtonColor: '#068923'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            window.location = '/admin/accueil'
                            window.localStorage.setItem('userID', response.data.pk)
                            window.localStorage.setItem('userFirstName', response.data.first_name)
                            window.localStorage.setItem('userName', response.data.first_name)
                            window.localStorage.setItem('userLastName', response.data.last_name)
                            window.localStorage.setItem('userFullname', response.data.first_name + ' ' + response.last_name)
                            window.localStorage.setItem('userType', response.data.username)
                            // window.localStorage.setItem('userFirstConnexion', response.data.data.first_connexion)
                            var userType = response.data.data.user_type
                            var isFirstConnexion = response.data.data.first_connexion
                        }
                      })
                   
                    //console.log(response.data.data)

                   

                    // Swal.fire({
                    //     icon: "success",
                    //     title: "Connexion réussie!",
                    //     iconColor: '#068923',
                    //     showCancelButton: false,
                    //     confirmButtonColor: '#068923',
                    //     cancelButtonColor: '#d33',
                    //     confirmButtonText: 'OK',
                    //     showConfirmButton: true,
                    //     allowOutsideClick: false,
                    //     timer: 3000,
                    // })
                    // this.setState({
                    //     isSuccessLogin: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         isSuccessLogin: false
                    //     })
                    // }, 2000);
                    // // window.location = '/admin/accueil'
                    // history.push('/admin/accueil')
                   
                }
            })
            .catch(error => {
                console.log(error)
                // Swal.fire({
                //     icon: "error",
                //     title: "Une erreur est survenue. Veuillez réessayer plus tard.!",
                //     iconColor: '#068923',
                //     showCancelButton: false,
                //     confirmButtonColor: '#068923',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'OK',
                //     showConfirmButton: true,
                //     allowOutsideClick: false,
                //     timer: 3000,
                // })
                this.setState({
                    isErrorLogin: true,
                    isLoginInProgress: false
                })

                setTimeout(() => {
                    this.setState({
                        isErrorLogin: false
                    })
                }, 5000);
            })
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="row login-page-row">
                        <div className="col-md-6 offset-md-3 right-side-col">
                        <EmptyHeader />

                            <div className="login-form-container">
                                <div className="login-form-introduction d-flex justify-content-center">
                                    <h1 className="login-title-label">Connectez-vous</h1>
                                </div>
                                <form id="login-form">
                                    <div className="input-group my-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text span-input-icon" id="basic-addon1">
                                                <FeatherIcon className="icon-input" icon="user" />
                                            </span>
                                        </div>
                                        <input name="username" id="username" onChange={(e) => this.handleChange(e)} type="text" className="form-control input-with-icon" placeholder="Identifiant" />
                                        {this.state.is_empty_username &&
                                            <div className="alert alert-danger gfa-alert-danger"> {this.state.errors.username} </div>
                                        }
                                    </div>
                                    <div className="input-group my-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text span-input-icon" id="basic-addon1">
                                                <FeatherIcon className="icon-input" icon="lock" />
                                            </span>
                                        </div>
                                        <input name="password" id="password" onChange={(e) => this.handleChange(e)} type="password" className="form-control input-with-icon" placeholder="Mot de passe" />
                                        {this.state.is_empty_password &&
                                            <div className="alert alert-danger gfa-alert-danger"> {this.state.errors.password} </div>
                                        }
                                    </div>

                                    <div className="dis-flex justify-content-end m-b-40">
                                        <button onClick={(e) => e.preventDefault()} type="button" className="mdpOublie" data-toggle="modal" data-target=".open-reset-password-modal">Mot de passe oublié ?</button>
                                    </div>

                                    <ResetPasswordModal />

                                    <div className="row submit-btn-row">
                                        <div className="col-md-8 offset-md-2 submit-btn-col p-4">
                                            {this.state.isLoginInProgress === false ?
                                                <button type="submit" onClick={(e) => this.onSubmitLogin(e)} className="btn-connexion">Connexion</button>
                                                :
                                                <button className="btn-connexion in-progress-btn">
                                                    Connexion en cours &nbsp;
                                                    <i className="fas fa-spin fa-spinner"></i>
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {this.state.isSuccessLogin || this.state.isErrorLogin || this.state.isInvalidCredential ?
                    <ToastContainer /> : ''
                } */}
            </>
        );
    }
}

