export const ACTIONS = {
    AUTH: 'AUTH',
     /*Recuperer tous les projets */
     CREATE_LIST_PROJECT: 'CREATE_LIST_PROJECT',
     PROJECT: 'PROJECT',
     PROJECT_FRONT: 'PROJECT_FRONT',
     PROJECT_EDIT: 'PROJECT_EDIT',
     CATEGORY_PROJECTS: 'CATEGORY_PROJECTS',
     CATEGORY_PROJECTS_CREATE: 'CATEGORY_PROJECTS_CREATE',
     CATEGORY_PROJECTS_EDIT: 'CATEGORY_PROJECTS_EDIT',
     ALL_USERS: 'ALL_USERS',
     ACTUALITES: 'ACTUALITES',
     ACTUALITES_FRONT: 'ACTUALITES_FRONT',
     CATEGORY_PROJECTS_FRONT:'CATEGORY_PROJECTS_FRONT',
     DETAILS_PROJECTS_TO_FRONT: 'DETAILS_PROJECTS_TO_FRONT',
     ID_PROJET_CONTRIBUER: 'ID_PROJET_CONTRIBUER',
     SLIDER: 'SLIDER',

     /*Recuperer un projet  */
 
}

export const updateItem = (data, id, post, type) => {
    const newData = data.map(item => item.id === id ? post : item)
    // console.log('new', post, id)
    return ({ type, payload: newData })
}

export const updateItemImageCouverture = (data, id, post, type) => {
    const newData = data.id === id ? post : data
    // console.log('new', post, id)
    return ({ type, payload: newData })
}

export const deleteItem = (data, id, type) => {
    const newData = data.filter(item => item.id !== id)
    return ({ type, payload: newData })
}
