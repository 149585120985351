import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import "./FrontRegister.css";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import { userRegister } from '../../../services/AuthServices'
import * as Yup from 'yup';
import api from "../../../Http/backend-base-url";
import axios from 'axios'

var FrontRegister = () => {


  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Le prénom est obligatoire'),
      username: Yup.string()
      .required('Le nom est obligatoire'),
    email: Yup.string().email("L'adresse email n'est pas valide").required('L\'email est obligatoire.'),
    password: Yup.string().required('Le mot de passe est obligatoire.'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], 'Les mots de passe ne correspondent pas!'),
    acceptTerms: Yup.bool()
      .oneOf([true], 'Vous devez accepter les condidtions d\'utilisations.')

  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;


  const router = useHistory()

  const [registerInProgress, setRegisterInProgress] = useState(false);

  const onSubmitRegister = async (data) => {

    console.log(data)


    var fd = new FormData();
    fd.append('first_name', data.username)
    fd.append('last_name', data.username)
    fd.append('email', data.email)
    fd.append('password', data.password)
    // fd.append('adresse', "non communique")
    // fd.append('phone', "non communique ")
    fd.append('passwordConfirmation', data.passwordConfirmation)

    setRegisterInProgress(true)
    axios
      .post(`http://fayalfidev.sen-gt.com:85/registration/`, fd)
      .then(async (response) => {
        console.log('responsnsnsns', response);
        if (response.status === 201 || response.status === 200) {
          window.localStorage.setItem('userID', response.data.id)
          window.localStorage.setItem('userEmail', response.data.data.email)
          window.localStorage.setItem('userFirstName', response.data.data.first_name)
          window.localStorage.setItem('userLastName', response.data.data.last_name)
          window.localStorage.setItem('userFullname', response.data.data.first_name)
          window.localStorage.setItem('userType', response.data.data.user_type)



          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Inscription réussi  avec succés',
            showConfirmButton: true,
            iconColor: '#068923',
            confirmButtonColor: '#068923'
          })
          reset();
          setRegisterInProgress(false);
          setTimeout(() => {
            // window.location = '/app/inscription-verification-mail'
            router.push('/app/inscription-verification-mail')

          }, 3000)
        }
      })
      .catch((e) => {
        console.log('errorrrooo', e.response);
        if (
          e.response.status === 400 || e.response.status === 401
        ) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter.',
            showConfirmButton: true,
            iconColor: '#068923',
            confirmButtonColor: '#068923'
          })
          setRegisterInProgress(false);


        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Une erreur est survenue lors de votre inscription !',
            showConfirmButton: true,
            iconColor: '#068923',
            confirmButtonColor: '#068923'
          })
          setRegisterInProgress(false);

        }
      })

  }


  return (
    <div className="component-front-register">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 d-flex px-0">
            <div className="formLogin-tck-front p-4">
              <form onSubmit={handleSubmit(onSubmitRegister)}>
                <div className="">
                  <h5 className="title-inscrivez-vous mt-5">Inscrivez-vous</h5>
                  <div className="row mt-5">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <input
                        className="form-control clean-border"
                        type="text"
                        name="username"
                        placeholder="Votre Prénom et Nom" {...register('username')}
                      />
                      {errors?.username && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {''}
                          {errors?.username?.message}{''}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <input
                        className="form-control clean-border"
                        type="text"
                        name="email"
                        placeholder="Email"
                        {...register('email')}
                      />
                      {errors?.email && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {''}
                          {errors?.email?.message}{''}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <input
                        className="form-control clean-border"
                        type="text"
                        name="phone"
                        placeholder="Telephone"
                        {...register('email')}
                      />
                      {errors?.email && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {''}
                          {errors?.email?.message}{''}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <input
                        className="form-control clean-border mt-5"
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        {...register('password')}
                      />
                      {errors?.password && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {''}
                          {errors?.password?.message}{''}
                        </div>
                      )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <input
                        className="form-control clean-border mt-5"
                        type="password"
                        name="passwordConfirmation"
                        placeholder="Confirmer mot de passe"
                        {...register('passwordConfirmation')}
                      />
                      {errors?.passwordConfirmation && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {''}
                          {errors?.passwordConfirmation?.message}{''}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-1 col-xl-1 col-lg-1 pr-0">
                        <div>
                          <input
                            className="form-check  border-input"
                            {...register('acceptTerms')} id="acceptTerms"
                            type="checkbox"
                            value=""
                            // id="defaultCheck1"
                            name="acceptTerms"
                          />
                        </div>
                      </div>
                      <div className="col-md-11 col-xl-11 col-lg-11 pl-0">
                        <div>
                          <span className="color-part1">
                            J'accepte les{" "}
                            <span className="color-part2">
                              conditions d'utilisations
                            </span>{" "}
                            et la{" "}
                            <span className="color-part2">
                              politique de confidentialité{" "}
                            </span>
                          </span>
                        </div>
                      </div>
                      {errors?.acceptTerms && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {''}
                          {errors?.acceptTerms?.message}{''}
                        </div>
                      )} 
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center py-5 ">
                    <div className="col-xl-6 col-lg-4 col-md-4">
                      {!registerInProgress ? (
                        <button
                          //  to="/app/inscription-verification-mail"
                          className="btn-connexion"
                        >
                          Creer un compte&nbsp;
                        </button>
                      ) : (
                        <button className="btn-theme-inscription">
                          Création en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>

                      )}
                      {/* <NavLink
                        to="/app/inscription-verification-mail"
                        className="btn-theme-inscription"
                      >
                        Creer un compte
                      </NavLink> */}
                    </div>
                  </div>

                  <div>
                    <p className="inscrivez-vous mt-3">
                      <font className="color-part1">
                        Vous avez deja un compte? Connectez-vous
                      </font>{" "}
                      <Link className="color-part2" to="/connexion">
                        ici
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 d-flex px-0">
              <div className="welcome-tck-front bienvenue-style">
                <h6 className="title-welcome ml-2"></h6>
                <div className=" my-4">
                    <p className="text-tck-login pb-3">
                    Bienvenue sur la plateforme <br />
                    digitale de Touba ça Kanam                    
                    </p>
                </div>
                <h1 className="text-welcome-connexion-tck mt-4 pb-3"> Laaj lu ñu man,
                  Def ko fu ñu xam.</h1>
                <p className="text-sous-title-inscription mt-4">
                Jaamu Yàlla ci jaami Yàlla yi
                </p>
                
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default FrontRegister;
