import React, { useContext,useState,useEffect } from 'react'
import './ActualiteFront.css'
import { Link, NavLink } from 'react-router-dom';
import { DataContext } from '../../../redux/store/GlobalState';
import Allarticle from '../../../assets/img/dashicons-arrow-right-alt2-3@2x.svg'
import CLOCK from '../../../assets/img/carbon-time@2x.svg'
import partage from '../../../assets/img/bi-share-fill@2x.svg'
import moment from 'moment';
import baseUrlFront from 'Http/frontend-base-url';
import ImageLabel from "../../../assets/images/image_label.png";

import {
    WhatsappShareButton,
  } from "react-share";


var ActualiteFront = () => {
    const { state, dispatch } = useContext(DataContext)
    let { actus_front } = state
    var [isPageLoading, setIsPageLoading] = React.useState(true);
    var [actufront, setlisteprojets] = useState([actus_front]);
   
    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
            window.scrollTo(0, 0)

        }, 2000);
    }, [actus_front])
    console.log('arti',actus_front);
   
    const url = (`http://tck-web.sen-gt.com/`)

   
    return (
        <div>
        {/* <div className="row  no-view-mobile">
            <div className="flex-col-4_projet pb-5 mb-5 pr-0">   
            <div className="overlap-group8">
              <div className="actualits-touba-ca-kanam heading-2-small">Actualités Touba ca kanam</div>
              <div className="rectangle-385-1"></div>
              <div className="flex-row-8">
                {actus_front?
                actus_front.slice(0, 3).map((item,index) => {
                    return (

                        <div className=" col-md-4 col-lg-4 col-sm-12" key={index}>
                            <div className="rectangle-385"></div>
                            <div className="frame-152">          
                            {
                                item?.actu_images?.length > 0 ?
                                item?.actu_images?.filter(x => x.display_order === parseInt(1)).map((image) => {
                                    return (
                                        <div className="overlap-group" style={{ backgroundImage: `url(${image.image})` }}>
                                        <div className="rectangle-372"></div>
                                    </div>                                        )
                                })
                                :
                                <div className="overlap-group" style={{ backgroundImage: ` url(${ImageLabel})` }}>
                                    <div className="rectangle-372"></div>
                                </div> 
                            }
                                
                            </div>
                            <NavLink className="" to={`/detail-actualite/${item?.id}`}>
                            <p className="ation heading-5-small">{`${item.title.substring(0, 40)}...`}</p>
                            </NavLink>

                            <p className="lorem-ipsum-dolor-si caption-small">{`${item.description.substring(0, 100)}...`}</p>
                            <div className="pt-1">
                            <img className="icon-clock" src={CLOCK}/>
                            <span className="address">{item?.date}</span>
                           <span className="time-published pl-3">{moment(item?.created_at).lang('fr').fromNow()}</span> 
                            
                            <WhatsappShareButton url={url}
                            quote={"Hello viens faire ton Barkelou!!"}
                            hashtag="#TCK">
                            <img className="bishare-fill-1" src={partage} />
                            </WhatsappShareButton>
                            </div>

                        </div>

                        
                    )} 
                    ) : ''
            }
           
              </div>
              <div className="flex-row-10">
                <NavLink to="/actualite-liste">
                  <div className="toute-lactualit caption-large">toute L'actualité</div>
                </NavLink>
                <img className="dashiconsarrow-right-alt2-3" src={Allarticle} />
              </div>
            </div>
            </div>
        </div> */}
         <section className="container-tck mb-4 py-5 bloc-detail">
         <div className=" pt-5">
             <div className="d-flex mb-5 mt-3">
               <h3 className="h3-theme mb-0 texte_decorative">Actualités Touba ca kanam </h3>
             </div>
           </div>
           <div className="row">
           {actus_front?
                actus_front.slice(0, 3).map((item,index) => {
                    return (
                     <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                         <div className="">         
                            <div className="card">
                            {
                                item?.actu_images?.length > 0 ?
                                item?.actu_images?.filter(x => x.display_order === parseInt(1)).map((image) => {
                                    return (
                            <img width="319" height="198" src={image.image ?  image?.image : ImageLabel} className="card-img-top" alt="Projet Item" />
                                    )
                                })
                                :
                                <img width="319" height="198" src={ImageLabel} className="card-img-top" alt="Projet Item" />

                            }
                        
                            <div className="card-body">
                                <NavLink className="" to={`/detail-actualite/${item?.id}`}>
                                <h5 className=" ation heading-5-small">{`${item.title.substring(0, 40)}...`}</h5>
                                </NavLink>
                                <p className="lorem-ipsum-dolor-si caption-small">{`${item.description.substring(0, 100)}...`}</p>
                                <div className="pt-5">
                                    <div className="pt-1">
                                    <img className="icon-clock" src={CLOCK}/>
                                    <span className="address">{moment(item?.date).lang('fr').fromNow()}</span>
                                        <WhatsappShareButton url={url}
                                        quote={"Hello viens faire ton Barkelou!!"}
                                        hashtag="#TCK">
                                        <img className="bishare-fill-1" src={partage} />
                                        </WhatsappShareButton>
                                    </div>
                                </div>

                            </div>
                        </div>
                         </div>
                     </div>
                           
                    )} 
                    ) : ''
            }
                     
                 </div>
                 <div className="text-right">
                <NavLink className="see-more trans-0-2 ml-auto" to="/actualite-liste" >Voir toutes les actualites</NavLink>
            </div>    
         </section>
         </div>
    )
}

export default ActualiteFront;
