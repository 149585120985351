import React, { Component } from "react";
import "./navbarMobileV2.css";
import { NavLink } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';


export default class navbarMobileV2 extends Component {
  
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
          <FeatherIcon  icon="align-left" />
          </span>
        </button>

        <div
          className="collapse navbar-collapse bg-theme nav-vertical-admin"
          id="navbarNavDropdown"
        >
          <div className="py-4">
            <div className="mobile-menu-container">
              <div className="mobile-menu-content">
                <ul className="mobile-menu-ul">
                  <li>
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/accueil"
                      activeClassName="actived"
                    >
                   <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="home" />

                      <span className="mobile-navlink-label">Tableau de bord</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/gestion-des-jayanters"
                      activeClassName="actived"
                    >
                      <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="users" />

                      <span className="mobile-navlink-label">
                        Jayanters
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/gestion-des-utilisateurs"
                      activeClassName="actived"
                    >
                      <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="user-plus" />
                      <span className="mobile-navlink-label">Administrateurs</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/gestion-des-projets"
                      activeClassName="actived"
                    >
                       <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="bar-chart-2" />
                      <span className="mobile-navlink-label">Projets</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/actualite"
                      activeClassName="actived"
                    >
                       <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="info" />
                      <span className="mobile-navlink-label">Actualites</span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/parametre"
                      activeClassName="actived"
                    >
                     <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="settings" />
                      <span className="mobile-navlink-label">Paramétres</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
