import React, { useContext, useEffect, useState } from 'react'
import './CarouselProjet.css'
import FeatherIcon from 'feather-icons-react';
// import Carousel, { consts } from 'react-elastic-carousel';
import ProjetItem from '../ProjetItem/ProjetItem';
import { NavLink } from 'react-router-dom';
import { DataContext } from '../../../redux/store/GlobalState';
import Direction from '../../../assets/img/dashicons-arrow-right-alt2-3@2x.svg';
 import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


var CarouselProjet = (props) => {
    const { state, dispatch } = useContext(DataContext)
    const { liste_projects_front } = state
    var [isPageLoading, setIsPageLoading] = React.useState(true);
    var [listeprojets, setlisteprojets] = useState([liste_projects_front]);

    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
            window.scrollTo(0, 0)

        }, 2000);
    }, [liste_projects_front])
    console.log('data',liste_projects_front);
    
    const breakPoints = [
        { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false, transitionMs: 500 },
        { width: 500, itemsToShow: 2, itemsToScroll: 1, pagination: false, transitionMs: 500 },
        { width: 768, itemsToShow: 3, itemsToScroll: 1, pagination: false, transitionMs: 500 },
        { width: 1200, itemsToShow: 4, itemsToScroll: 1, pagination: false, transitionMs: 500 }

    ];
    const items = [
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
    ];

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    return (
        <>
            {/* <h1 className="projets-en-cours heading-2-small text-left">Projet en cours</h1> */}
            
            <div className="component-carousel-projet">
                
                {/* <div className="rectangle-375-1"></div> */}
                {/* <div className="container-ahj mb-5 pr-0 p-5">
                    <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500} itemPosition={consts.START} >
                        {liste_projects_front?.map((item, index) => (
                            <ProjetItem item={item} key={index}  className="mx-4" />
                        ))}
                    </Carousel>
                    <div className="text-right">
                        <NavLink className="tous-les-projets caption-large" to="/" >tous les projets</NavLink>
                        <img className="dashiconsarrow-right-alt2-2" src={Direction} />
                    </div>
                </div> */}
                <div className=" container-tck mb-4 py-5">
                <div className=" pt-5">
                    <div className="d-flex mb-5 mt-3">
                    <h3 className="h3-theme mb-0 decorative_border">Projet en cours </h3>
                    </div>
                </div>
                {
                    
                    liste_projects_front?.length > 0 ? 
                    (
                        <Carousel
                    responsive={responsive}
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {liste_projects_front?.map((item, index) => (
                        <ProjetItem item={item} key={index} className="mx-4" />
                    ))} 
                </Carousel>
                    ):
                    <h1>Pas de projets disponibles</h1>

                }
                {/* <NavLink className="tous-les-projets caption-large" to="/" >tous les projets</NavLink>
                    <img className="dashiconsarrow-right-alt2-2" src={Direction} /> */}
                </div>
            </div>
        </>
    )
}

export default CarouselProjet;
