 import axios from 'axios'
import React, { createContext, useReducer, useEffect } from 'react'
import api from '../../Http/backend-base-url'
import { getData } from '../utils/fetchData'
import reducers from './Reducers'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {

    const initialState = {
        auth: {},
        list_projects:[],
        category_projects_front:[],
        users:[],
        project: {},
        slider:[],
        details_projects_front: {},
        list_projects_front:[],
        id_projet_contribuer: {},
        category_project:{},
        category_projects: [],
        actus: [],
        actus_front:[],
    }

    const [state, dispatch] = useReducer(reducers, initialState)
    const {
        auth,
        list_projects,
        category_projects,
        category_project,
        selectedOption,
        user,
        
    } = state

    const token = window.localStorage.getItem('userToken');
    let requestConfig = {
        headers: { Authorization: `Token ${token}` }
    }
    // const user_type = window.localStorage.getItem('userType');


    /*Recuperation auth user */
    // useEffect(() => {
    //     if (token) {
    //         getData(`me/`, requestConfig)
    //             .then((res) => {
    //                 dispatch({
    //                     type: 'AUTH',
    //                     payload: res,
    //                 })
    //             })
    //             .catch((e) => {
    //                 console.log("error", e)
    //             })
    //     }
    // }, [token])
    /*Recuperation  user */
    useEffect(() => {
            axios.get(`${api}api/members`)
                .then((res) => {
                    // console.log('users',res.data);
                    dispatch({
                        type: 'ALL_USERS',
                        payload: res.data,
                    })
                })
                .catch((e) => {
                    console.log("error", e)
                })
    }, [])
    /*Recuperation  user */
    // useEffect(() => {
    //     if (token) {
    //         getData(`user/`, token)
    //             .then((res) => {
    //                 dispatch({
    //                     type: 'GET_ALL_USERS',
    //                     payload: res.results,
    //                 })
    //             })
    //             .catch((e) => {
    //                 console.log("error", e)
    //             })
    //     }
    // }, [token])
    /*Recuperation auth user */

    /*Recuperation project */
    useEffect(() => {
        // if (token && user_type === "admin") {
        //Pour recuperer les project
        getData(`api/projects/`, token)
            .then((res) => {
                // console.log('test',res)
                dispatch({
                    type: 'CREATE_LIST_PROJECT',
                    payload: res,
                })
            })
            .catch((e) => {
                console.log("error", e)
            })
     
    }, [token])

    useEffect(() => {
        //Pour recuperer les project
        axios.get(`${api}api/actus/`,token)
            .then((res) => {
                // console.log('data actus',res.data);
                dispatch({
                    type: 'ACTUALITES',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.log("error", e)
            })
    },[token])

    
    useEffect(() => {
        //Pour recuperer les project
        axios.get(`${api}api/actus/front`)
            .then((res) => {
                // console.log('data actus FRONT',res.data);
                dispatch({
                    type: 'ACTUALITES_FRONT',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.log("error", e)
            })
    },[])
    useEffect(() => {
        //Pour recuperer les project
        axios.get(`${api}api/projects/front/`,token)
            .then((res) => {
                console.log('data PROJECT FRONT',res.data);
                dispatch({
                    type: 'PROJECT_FRONT',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.log("error", e)
            })
    },[token])
    useEffect(() => {
        //Pour recuperer les project
        axios.get(`${api}api/domains/front/`)
            .then((res) => {
                console.log('data domaine FRONT',res.data);
                dispatch({
                    type: 'CATEGORY_PROJECTS_FRONT',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.log("error", e)
            })
    },[])
   
    useEffect(() => {
        //Pour recuperer les project
        if(token){
            axios.get(`${api}api/domains/`,requestConfig)
            .then((res) => {
                // console.log('domaine',res);
                dispatch({
                    type: 'CATEGORY_PROJECTS',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.log("error", e.response)
            })
        }
       
    }, [token])
    useEffect(() => {
        //Pour recuperer les project
        axios.get(`${api}api/slider/`)
            .then((res) => {
                console.log('data slider',res.data);
                dispatch({
                    type: 'SLIDER',
                    payload: res.data,
                })
            })
            .catch((e) => {
                console.log("error", e)
            })
    },[])

    return (
        <DataContext.Provider value={{ state, dispatch }}>
            {children}
        </DataContext.Provider>
    )
}
