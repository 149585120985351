import React, { useContext, useEffect, useState } from 'react';
import './frontHeader.css';
import { NavLink } from 'react-router-dom';
import TckLogo from "../../../assets/images/NEW-LOGO- TCK.png";
import { User } from 'react-iconly'
 import { useHistory, Link } from 'react-router-dom';



var FrontHeader = (props) => {
   
    var history = useHistory()

    var logout = (e) => {
        e.preventDefault()
        localStorage.removeItem("userToken");
        window.localStorage.clear();
            setTimeout(() => {
                //window.location.href = '/admin-login'
                history.push('/')
            }, 1000);
       

    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])  

    return <div className="component-front-header">
                {/* <img className="x13-2-1" src={x131} alt="tck" /> */}
        <div className="header-scrolling-container" id="frontHeader">
            <nav className="navbar navbar-expand-lg  container-tck navbar-light header py-4">
                <a className="navbar-brand" href="/">
                    <img className="logo" alt={"TckLogo"} src={TckLogo} />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeaderSecondary" aria-controls="navbarHeaderSecondary" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarHeaderSecondary">
                    <ul className="navbar-nav mx-auto py-lg-0 py-4">
                        <li className="nav-item nav-tck px-lg-2">
                            <NavLink exact className="nav-link trans-0-2" to="/">Accueil</NavLink>
                        </li>
                        <li className="nav-item nav-tck px-lg-2">
                            <NavLink className="nav-link trans-0-2" to="/projets-en-cours">Projet</NavLink>
                        </li>
                         <li className="nav-item nav-tck px-lg-2">
                            <NavLink className="nav-link trans-0-2" to="/actualite-liste">Actualités</NavLink>
                        </li>
                        <li className="nav-item nav-tck px-lg-2">
                            <NavLink className="nav-link trans-0-2" to="/a-propos">A propos</NavLink>
                        </li>
                    </ul> 
                     {/* <div className="sinscrire-container-1 body-large">
                    <NavLink className="sinscrire-1 trans-0-2" to="/login" >S'inscrire</NavLink>
                    </div>
                    <div className="se-connecter-container-1 body-large">
                    <NavLink className="se-connecter-2" to="/login" >Connexion</NavLink>
                    </div> */}
                    {window.sessionStorage.getItem('userType') ?
                        <div className="">
                            {/* A afficher si l'utilisateur n'est pas connecté */}
                            {/* <NavLink className="link-header-login trans-0-2 d-block" to="/login" >Connexion</NavLink> */}

                            {/* A affVGVVicher si l'utilisateur est connecté */}
                            <div className="dropdown connexion-dropdown">
                                <a className="dropdown-toggle trans-0-2" href="#" role="button" id="connexionDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="name-user-connect"> {window.sessionStorage.getItem('userFullname')} </span>
                                    <span className="icon image-responsive mx-2 p-2 " >
                                        {/* <User set="bold" primaryColor="#BB4411" /> */}
                                    </span>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="connexionDropdown">
                                    <NavLink className="dropdown-item no-bg border-bottom" to="/tableau-de-bord-porteur-projet">
                                        Compte
                                    </NavLink>
                                    <NavLink className="dropdown-item no-bg border-bottom"  to="/inscription-information-personnelle">
                                       Informations Personnelles
                                    </NavLink>
                                    <NavLink className="dropdown-item no-bg border-bottom"  to="/modifier-mot-de-passe-porteur-projet">
                                       Changer Mon Mot de Passe
                                    </NavLink>
                                    <NavLink className="dropdown-item no-bg" to="" onClick={(e) => logout(e)}>
                                        Deconnexion
                                    </NavLink>
                                </div>
                            </div>
                            <div className="d-md-none d-inline-block mt-4 language-selector-mobile">
                            </div>
                        </div>
                        :
                        // <div className="">
                        //     <NavLink className="link-header-login trans-0-2 d-block" to="/connexion" >Connexion</NavLink>
                        // </div>
                        ''
                    }
                </div>
            </nav>
        </div>
    </div>;
}

export default FrontHeader;
