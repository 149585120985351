import React, {Component} from 'react'
import FeatherIcon from 'feather-icons-react';
 import api from "../../Http/backend-base-url";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios'
import TckLogo from "../../assets/images/tck-logo.jpeg";


export default class ResetPassword extends Component{
    constructor(props){
        super(props)
        this.state = {
            fields: {},
            errors: {},
            InProgress: false,
            isSuccess: false,
            isError: false,
            is_empty_email: false,
            is_empty_new_password: false,
            is_empty_new_password_confirm: false,
            is_invalid_email: false,
            is_empty_code: false,
            is_not_same_password: false,

        }

        this.handleChange = this.handleChange.bind(this)
        this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this)
    }

    componentDidMount(){

    }

    handleChange(e){
        let fields = this.state.fields
        fields[e.target.name] = e.target.value

        this.setState({
            fields: fields
        })
        console.log(fields)
    }

    onValidateResetForm(){
        let fields = this.state.fields
        let errors = this.state.errors
        var isValidForm = true

        if(!fields['code']){
            isValidForm = false
            this.setState({
                is_empty_code: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_code: false
                })
            }, 5000);

            errors['code'] = 'Ce champ est obligatoire'
        }
        if(!fields['email']){
            isValidForm = false
            this.setState({
                is_empty_email: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_email: false
                })
            }, 5000);

            errors['email'] = 'Ce champ est obligatoire'
        }
        if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                isValidForm = false
                this.setState({
                    is_invalid_email: true
                })
                setTimeout(() => {
                    this.setState({
                        is_invalid_email: false
                    })
                }, 5000);
    
                errors['invalid_email'] = 'Votre adresse email n\'est pas valide'
            }
        }
        if(!fields['new_password']){
            isValidForm = false
            this.setState({
                is_empty_new_password: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_new_password: false
                })
            }, 5000);

            errors['new_password'] = 'Ce champ est obligatoire'
        }
        if(!fields['new_password_confirm']){
            isValidForm = false
            this.setState({
                is_empty_new_password_confirm: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_new_password_confirm: false
                })
            }, 5000);

            errors['new_password_confirm'] = 'Ce champ est obligatoire'
        }
        if(typeof fields["new_password"] !== "undefined" && typeof fields["new_password_confirm"] !== "undefined"){
            if (fields['new_password'] !== fields['new_password_confirm']) {
                isValidForm = false
                this.setState({
                    is_not_same_password: true
                })
                setTimeout(() => {
                    this.setState({
                        is_not_same_password: false
                    })
                }, 5000);

                errors['not_same_password'] = 'Les deux mots de passe ne sont pas identiques'
            }
            
        }

        this.setState({
            errors: errors
        })

        return isValidForm;
    }

    onSubmitResetPassword(e){
        e.preventDefault()

        if(this.onValidateResetForm()){
            this.setState({
                InProgress: true
            })

            var data = this.state.fields

            axios.post(api + 'reset-password/', data)
                .then(response => {
                    this.setState({
                        InProgress: false
                    })
                    if(response.status === 200 || response.status === 201){
                        toast.success('Votre mot de passe a été modifié avec succès. Vous serez redirigé vers la page de connexion dans 5 seondes.')
                        this.setState({
                            isSuccess: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isSuccess: false
                            })
                            window.location = '/'
                        }, 6000);
                    }else{
                        console.log(response)
                        toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
                        this.setState({
                            isError: true,
                        })

                        setTimeout(() => {
                            this.setState({
                                isError: false
                            })
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
                    this.setState({
                        isError: true,
                        InProgress: false
                    })

                    setTimeout(() => {
                        this.setState({
                            isError: false
                        })
                    }, 5000);
                })
        }
    }

    render(){
        return(
            <>
                <div className="reset-password-component">
                    <div className="p-t-50">
                        <div className="dis-flex">
                            <div className="col-md-6 offset-md-3 ml-auto mr-auto reset-effective-password-container">
                                <div className="card-reset-password">
                                    <div className="d-flex justify-content-center align-items-center border-0 rounded-lg" style={{ background: '#ffffff' }}>
                                        <img src={TckLogo} alt="Logo TCK"height={50} />
                                    </div>
                                    <p className="titre-connexion">Modifiez votre mot de passe</p>
                                    <form id="reset-form">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text span-input-icon" id="basic-addon1">
                                                    <FeatherIcon className="icon-input" icon="hash" />
                                                </span>
                                            </div>
                                            <input autoComplete="off" name="code" id="code" onChange={ (e) => this.handleChange(e) } type="text" className="form-control input-with-icon" placeholder="Le code reçu par email" />
                                            {this.state.is_empty_code &&
                                                <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.code } </div>
                                            }
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text span-input-icon" id="basic-addon1">
                                                    <FeatherIcon className="icon-input" icon="mail" />
                                                </span>
                                            </div>
                                            <input autoComplete="off" name="email" id="email" onChange={ (e) => this.handleChange(e) } type="email" className="form-control input-with-icon" placeholder="Adresse mail" />
                                            {this.state.is_empty_email &&
                                                <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.email } </div>
                                            }
                                            {this.state.is_invalid_email &&
                                                <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.invalid_email } </div>
                                            }
                                        </div>
                                        
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text span-input-icon" id="basic-addon1">
                                                    <FeatherIcon className="icon-input" icon="lock" />
                                                </span>
                                            </div>
                                            <input autoComplete="new-password" name="new_password" id="new_password" onChange={ (e) => this.handleChange(e) } type="password" className="form-control input-with-icon" placeholder="Votre nouveau mot de passe" />
                                            {this.state.is_empty_new_password &&
                                                <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.new_password } </div>
                                            }
                                        </div>

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text span-input-icon" id="basic-addon1">
                                                    <FeatherIcon className="icon-input" icon="lock" />
                                                </span>
                                            </div>
                                            <input autoComplete="new-password" name="new_password_confirm" id="new_password_confirm" onChange={ (e) => this.handleChange(e) } type="password" className="form-control input-with-icon" placeholder="Confirmez votre nouveau mot de passe" />
                                            {this.state.is_empty_new_password_confirm &&
                                                <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.new_password_confirm } </div>
                                            }
                                            {this.state.is_not_same_password &&
                                                <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.not_same_password } </div>
                                            }
                                        </div>
                
                                        {this.state.InProgress === false ?
                                            <button type="submit" onClick={ (e) => this.onSubmitResetPassword(e) } className="btn-password m-t-50">Envoyer</button>
                                            :
                                            <button className="btn-connexion in-progress-btn m-t-50">
                                                Envoi en cours &nbsp;
                                                <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                        {this.state.isSuccess || this.state.isError ?
                            <ToastContainer /> : ''
                        }
                    </div>
                </div>
            </>
        )
    }
}