import React, { useContext,useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import './HomeBanner.css';
import { DataContext } from '../../../redux/store/GlobalState';
import baseUrl from '../../../Http/backend-base-url';
import image from '../../../assets/img/vector@2x.svg'
import image1 from '../../../assets/almoul.jpeg'
import Barkelou from '../../../assets/img/frame@1x.svg'
import Carousel from 'react-bootstrap/Carousel';
import FrontHeader from '../frontHeader/index';

var HomeBanner = () => {
    const { state, dispatch } = useContext(DataContext)
    let { slider } = state
    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
        }, 2000);
    }, [slider])
    console.log("slider",slider);
    return (
        <>
          {/*   <div class="container-fluid text-center py-3 py-3-lg py-1-sm" >
            <div class="container text-center py-3 py-3-lg py-1-sm">
                <div class="row  my-5 py-3-md py-5 py-1-sm">
                    <div class="col-md-6 col-lg-6 col-sm-4 py-3 py-3-lg py-1-sm">
                        <h1 className="laaj-lu-u-man-def-ko-fu-u-xam display">Laaj lu ñu man,<br />Def ko fu ñu xam.</h1>
                        <p className="jaamul-ylla-ci-jaam caption-large">Jaamul Yàlla ci jaami Yàlla yi, bul xeeb dara bul yéem dara.</p>
                        <div className="button-1">
                            <div className="button-filledmm-false-false-true-default">
                                <NavLink className="button inter-semi-bold-white-16px" to="/contribution">Contribuer</NavLink>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 py-3  py-3-lg py-1-sm">
                        <img className="vector" src={image} alt="tck" />
                        <img className="frame" src={Barkelou} alt="tck"/>
                    </div>

                </div>
            </div>
            </div>
        <div className='container-fluid'>
                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="flex-col-3">
                        <h1 className="laaj-lu-u-man-def-ko-fu-u-xam display">Laaj lu ñu man,<br />Def ko fu ñu xam.</h1>
                        <p className="jaamul-ylla-ci-jaam caption-large">Jaamul Yàlla ci jaami Yàlla yi, bul xeeb dara bul yéem dara.</p>
                        <div className="button-1">
                            <div className="button-filledmm-false-false-true-default">
                                <button className="button inter-semi-bold-white-16px">Contribuer</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="overlap-group10">
                            <img className="vector" src={image} />
                            <img className="frame" src={Barkelou} />
                        </div>
                    </div>
                </div>
            </div> */}
    <div class="container-fluid text-center py-3 py-3-lg py-1-sm" style={{backgroundColor:'#FFFF'}}  >
    <FrontHeader />
    <Carousel>
       {
        slider?.length > 0 ?
        slider.map((image) => {
            return (

                <Carousel.Item>
                <img
                  className="d-block w-100 normal-img" key={image.id}
                  src={image.image}
                alt={image.title}
                 height={700}
                // style={{height:'auto'}}                
                />
                
                <Carousel.Caption>
                <div class="container text-center py-6 py-6-lg py-1-sm"  >
                        <div class="row  my-5 py-3-md py-5 py-1-sm">
                            {/* <div class="col-md-6 col-lg-6 col-sm-4 py-3 py-3-lg py-1-sm">
                                <h1 className="laaj-lu-u-man-def-ko-fu-u-xam display">Laaj lu ñu man,<br />Def ko fu ñu xam.</h1>
                                <p className="jaamul-ylla-ci-jaam caption-large">Jaamul Yàlla ci jaami Yàlla yi, bul xeeb dara bul yéem dara.</p>
                                <div className="button-1">
                                    <div className="button-filledmm-false-false-true-default">
                                        <NavLink className="button inter-semi-bold-white-16px" to="/contribution">Contribuer</NavLink>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div class="col-md-2 col-lg-2  py-3 py-3-lg py-1-sm">
                            </div>
                            <div class="col-md-8 col-lg-8 col-sm-12 py-3 py-3-lg py-1-sm">
                                <div className="button-1  btn-lg btn-block">
                                    <div className="button-filledmm-false-false-true-default-barkelou">
                                        <NavLink className="button inter-semi-bold-white-16px-barkelou" to="/contribution">Barkeelou</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-2  py-3 py-3-lg py-1-sm">
                            </div> */}
                        </div>
                    </div>
                </Carousel.Caption>
              </Carousel.Item>)
        })
        :
       ''
        
       }     
   
    </Carousel>
    </div>     
        </>
    )
}

export default HomeBanner;
