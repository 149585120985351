import React from 'react';
import Logo from '../../assets/images/tck-logo.jpeg'

var EmptyHeader = () => {
    return( 
        <div className="container-fluid empty-fluidy d-flex justify-content-center">
            <div className="empty-header">
                <div className="logo-item">
                <div className="logo-container">
                    <img src={Logo} className="logo-img" alt="Tck"  height={50}/>
                </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyHeader;