import React, { useContext,useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './ProjetBanner.css';
import { DataContext } from '../../../redux/store/GlobalState';
import image from '../../../assets/img/frame-1@2x.svg'
import Barkelou from '../../../assets/img/frame-3@2x.svg'
import projet from '../../../assets/img/group@2x.svg'
import frame1 from '../../../assets/img/frame-2@2x.svg'
import Pagination from 'react-bootstrap/Pagination';
import frame8 from '../../../assets/img1/img/frame-8@2x.png'
import frame9 from '../../../assets/img1/img/frame-9@2x.png'
import frame10 from '../../../assets/img1/img/frame-10@2x.png';
import frame11 from '../../../assets/img1/img/frame-11@2x.png';
import backimage from '../../../assets/images/slider.png';
import backimage1 from '../../../assets/img1/Frame1.png';
import FrontFooter from '../frontFooter/frontFooter';
import projet1 from '../../../assets/img1/img/frame-215@2x.svg';
import axios from 'axios';
import api from 'Http/backend-base-url';
import DomaineMobile from './DomaineMobile';

var ProjetBanner = () => {

    const { state, dispatch } = useContext(DataContext)
    console.log('testtsts',state);
    let { liste_projects_front,category_projects_front,users } = state
    var [isPageLoading, setIsPageLoading] = React.useState(true);
        // To hold the actual data
        const [project, setProject] = useState()
        const [secteur] = useState([])
        console.log(secteur);
        const detailProject = (datas) => {
            console.log(datas);
            dispatch({ type: 'DETAILS_PROJECTS_TO_FRONT', payload: datas})
            dispatch({ type: 'ID_PROJET_CONTRIBUER', payload: datas?.id })
        }
        const [data, setData] = useState([])
        const [loading, setLoading] = useState(true);
        useEffect(() => {
            //Pour recuperer les project
            axios.get(`${api}api/projects/front/`)
                .then((res) => {
                  setData(res.data)
                //   console.log('mydata',res.data);
                })
                .catch((e) => {
                    console.log("error", e)
                })
        },[])
        const handleChangeSecteur = (e) => {
            let value =  e.target.value
            // setSecteur( e.target.value)
            // setProject("")
             console.log('test',value);
             if(value=="all"){
                axios.get(`${api}api/projects/front/`)
                .then((res) => {
                  setData(res.data)
                  if(res.status ===200){
                    category_projects_front = res.data
                    console.log('result no filter',category_projects_front);
                }
                })
                .catch((e) => {
                    console.log("error", e)
                })
             }else{ 
                axios.get(`${api}api/projects/domain/${value}`)
                .then((res) => {
                setData(res.data)
                if(res.status ===200){
                    secteur = res.data
                }
                console.log('mydatafilrtrerin',res);
                })
            .catch((e) => {
                console.log("error", e)
            })
             
        }
        }
       

      
        useEffect(() => {
            //Pour recuperer les project
            axios.get(`${api}api/projects/front/`)
                .then((res) => {
                  setData(res.data)
                //   console.log('mydata',res.data);
                })
                .catch((e) => {
                    console.log("error", e)
                })
        },[])
        // User is currently on this page
        const [currentPage, setCurrentPage] = useState(1);
        // No of Records to be displayed on each page   
        const [recordsPerPage] = useState(3);
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = data.slice(indexOfFirstRecord, 
            indexOfLastRecord);
            const nPages = Math.ceil(data.length / recordsPerPage)
            const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
 

       
      
        const url = `http://tck-web.sen-gt.com/contribution-projet/`

        const nextPage = () => {
            if(currentPage !== nPages) 
                setCurrentPage(currentPage + 1)
        }
        const prevPage = () => {
            if(currentPage !== 1) 
                setCurrentPage(currentPage - 1)
        }
        
       
    return (
        <div className="container-center-horizontal">
            <div className='projets-en-cours-1 screen'>
                <div className="flex-row-25">
                    <div className="frame-container-3">
                        <img className="frame-3" src={image} alt="projet" />
                        <img className="frame-4" src={Barkelou} alt="projet"/>
                        <div className="group-1709-2">
                    <div className="overlap-group-12">
                   </div>
                </div>
                    </div>
                    <img className="frame-212" src={projet} alt="projet" />
                    <img className="frame-7" src={frame1} alt="projet"/>
                </div>
                 
            <section className="container-tck ">
            <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 bloc-detail">
                        <div className="row  my-5 py-3-md py-2 py-1-sm bloc-detail ">
                            <div className="open_domain_mobile" style={{display:"none"}}>   
                            <DomaineMobile />
                            </div>
                            <div className="col-md-8 col-lg-8 col-sm-12">
                            <div className="row ">
                                { 
                                
                                secteur?.length > 0 ?
                                secteur?.map((details_projects_front, index) => {
                                return (
                                <div className="frame-284-1_okay_all_project border-1px-grey-tck-contour">
                                        {
                                        details_projects_front?.project_images?.length  > 0 ?
                                        details_projects_front?.project_images?.map((image) => {
                                        return (
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                                                <img
                                                src={image.image}
                                                alt="imagedétails projet"
                                                className="img-details_projets_all"
                                                height="244"
                                                width="417"
                                                />            
                                        </div>  )
                                            })
                                            :
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                                                <img
                                                src={backimage1}
                                                alt="imagedétails projet"
                                                className="img-details_projets"
                                                height="244"
                                                width="417"
                                                />            
                                         </div>  
                                        }
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
                                            <div className="card-body">
                                                <div className="construction-de-la-route-de-ndamatou_projet heading-2-small23 ">
                                                {details_projects_front.title} 
                                                </div>                                   
                                            </div>
                                            <div className="pt-0"> 
                                                <div className="">
                                                    <div className="d-flex justify-content-end pt-0">
                                                        <small className="text-right">
                                                         {details_projects_front.estimated_budget} FCFA
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="lorem-ipsum-dolor-si-12_dtail_responsive caption-large">{`${details_projects_front.description.substring(0, 250)}...`}
                                            </p>
                                                <div className="frame-295_banner">
                                                <NavLink className="button inter-semi-bold-white-16px" to={`/contribution-projet/${details_projects_front?.id}`}>
                                                <div className="contribuer-au-projet heading-5-small">Contribuer au projet</div>
                                                </NavLink>
                                                </div>
                                        </div>
                                </div>
                                )
                                
                                })
                                :
                                currentRecords?.map((details_projects_front, index) => {
                                    return (
                                    <div className="frame-284-1_okay_all_project border-1px-grey-tck-contour">
                                            {
                                            details_projects_front?.project_images?.length  > 0 ?
                                            details_projects_front?.project_images?.map((image) => {
                                            return (
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                                                    <img
                                                    src={image.image}
                                                    alt="imagedétails projet"
                                                    className="img-details_projets_all"
                                                    height="244"
                                                    width="417"
                                                    />            
                                            </div>  )
                                                })
                                                :
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                                                    <img
                                                    src={backimage1}
                                                    alt="imagedétails projet"
                                                    className="img-details_projets"
                                                    height="244"
                                                    width="417"
                                                    />            
                                             </div>  
                                            }
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
                                                <div className="card-body">
                                                    <div className="construction-de-la-route-de-ndamatou_projet heading-2-small23 ">
                                                    {details_projects_front.title} 
                                                    </div>                                   
                                                </div>
                                                <div className="pt-0"> 
                                                    <div className="">
                                                        <div className="d-flex justify-content-end pt-0">
                                                            <small className="text-right">
                                                             {details_projects_front.estimated_budget} FCFA
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="lorem-ipsum-dolor-si-12_dtail_responsive caption-large">{`${details_projects_front.description.substring(0, 250)}...`}
                                                </p>
                                                    <div className="frame-295_banner">
                                                    <NavLink className="button inter-semi-bold-white-16px" to={`/contribution-projet/${details_projects_front?.id}`}>
                                                    <div className="contribuer-au-projet heading-5-small">Contribuer au projet</div>
                                                    </NavLink>
                                                    </div>
                                            </div>
                                    </div>
                                    )
                                    
                                    })

                            }      
                            </div>
                            </div>
                            <br></br>
                             {/* <div className='open_domain_only_desktop'> */}
                              <div className=''>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <div className="flex-col-15">
                                        <div className="thmes heading-2-small">Domaines</div>
                                        <div className="rectangle-364"></div>
                                        <div className="flex-row-27">
                                            <div className="tout body-large">Tout</div>
                                            <div className="checkbox">
                                                <input
                                                    className="check-2  border-input"
                                                    id="acceptTerms"
                                                    type="radio"
                                                    value="all"
                                                    name="domaine"
                                                    onChange={handleChangeSecteur}
                                                />                                    
                                            </div>
                                        </div>
                                        {
                                        category_projects_front?
                                        category_projects_front?.map((domaine) => {
                                        return(
                                            <div className="">
                                                <div className="rectangle-3"></div>
                                                <div className="flex-row-27" key={'domains' + domaine.id}>
                                                    <div className="education body-large">{domaine.title}</div>
                                                    <div className="checkbox-1 border-1px-mischka ">
                                                        <input
                                                            className="check-2"
                                                            id="acceptTerms"
                                                            type="radio"
                                                            value={domaine.id}
                                                            name="domaine"
                                                            onChange={handleChangeSecteur}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        })
                                        :
                                        ''
                                    }
                                    
                                    <img className="" src={projet1} alt="projet" />

                                    </div>
                                </div>
                            </div>
                            </div>
                            <nav aria-label="...">
                            <ul className="pagination">
                                <li className="page-item disabled">
                                <Link className="page-link" href="#" tabindex="-1"
                                onClick={prevPage}>Previous</Link>
                                </li>
                                {
                                    pageNumbers?.map((pgNumber) =>{
                                        return(
                                        <li className={`page-item active ${currentPage== pgNumber ? 'active' :''}`} key={pgNumber}
                                        >
                                        <Link className="page-link" onClick={() =>setCurrentPage(pgNumber) } href="#">{pgNumber} <span className="sr-only">(current)</span></Link>
                                        </li>
                                    )})
                                }
                            
                                <li className="page-item">
                                <Link className="page-link" href="#" onClick={nextPage} >Next</Link>
                                </li>
                            </ul>
                        </nav>
                      
                        
                    </div>
                  
            </div>
            </section>
            <FrontFooter />

        </div>
        </div> 
    )
}

export default ProjetBanner;
