import React, {Component} from 'react';
import './navbarContent.css';
import { NavLink } from 'react-router-dom';
// import FeatherIcon from 'feather-icons-react';
import * as IconlyPack from 'react-iconly';
import FeatherIcon from 'feather-icons-react';

export default class navbarContent extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    
    render() {
      return(
        <div className="hoverable"  id="scrollbar">
          <ul className="nav nav-items-custom flex-column">
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/accueil" activeClassName="actived">
                    {/* <IconlyPack.Chart set="bold" primaryColor="white" className="icon-navbar-vertical"/> */}
                    <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="home" />

                    <span className="mobile-navlink-label">Tableau de bord</span>
                </NavLink>
            </li> 
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/gestion-des-jayanters" activeClassName="actived">
                <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="users" />
                    <span className="mobile-navlink-label">Barkelou</span>
                </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/gestion-des-utilisateurs" activeClassName="actived">
                <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="user-plus" />
                    <span className="mobile-navlink-label">Administrateurs</span>
                </NavLink>
            </li> 
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/gestion-des-projets" activeClassName="actived">
                    <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="bar-chart-2" />
                    <span className="mobile-navlink-label">Projets</span>
                </NavLink>
            </li> 
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/domaine-des-projets" activeClassName="actived">
                    <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="bookmark" />
                    <span className="mobile-navlink-label">Categorie</span>
                </NavLink>
            </li> 
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/actualites" activeClassName="actived">
                <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="info" />
                    <span className="mobile-navlink-label">Actualites</span>
                </NavLink>
            </li> 
            <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/admin/parametre" activeClassName="actived">
                    {/* <IconlyPack.Setting set="bold" primaryColor="white" className="icon-navbar-vertical"/> */}
                    <FeatherIcon className="icon-navbar-vertical" set="bold" primaryColor="white" icon="settings" />
                    <span className="mobile-navlink-label">Paramétres</span>
                </NavLink>
            </li> 
            {/* <li className="nav-item nav-item-vertical-custom">
                <NavLink className="nav-link nav-link-vertival-custom" to="/listing" activeClassName="actived">
                    <FeatherIcon className="icon-navbar-vertical" icon="book" />
                    <span className="mobile-navlink-label">Listing</span>
                </NavLink>
            </li> */}
          </ul>
        </div>
      );
    }
}
