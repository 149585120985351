import React ,{useEffect} from 'react';
import './frontHome.css';
import FrontHeader from '../frontHeader/index';
// import FrontFooter from '../frontFooter/index';

import HomeBanner from '../HomeBanner/HomeBanner';
import SuivieProjet from '../SuivieProjet/SuivieProjet';
import CarouselProjet from '../CarouselPojet/CarouselProjet';
import ActualiteFront from '../ActualiteFront/ActualiteFront';
import imageContainer from '../../../assets/img/image-6@2x.png'
import  image7  from '../../../assets/img/image-7-6@2x.png' 
import  image82  from '../../../assets/img/image-8-3@2x.png' 
import CarouselEquipe from '../CarouselEquipe/CarouselEquipe';
import FrontFooter from '../frontFooter';
import CookieConsent from "react-cookie-consent";

var FrontHome = (props) => {

//   const { state, dispatch } = useContext(DataContext)
//   let { appel_offres_front,offre_emplois_front } = state;

//   useEffect(() => {
    
//   })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  


  return <div className="">
    
    {/* <FrontHeader /> */}
      <div className="content-view-start">
        <HomeBanner />
        <section className="container-tck ">
          <SuivieProjet />
        </section>
        <section  className="container-tck ">
          <CarouselProjet />
        </section>
        <section className="container-tck ">
          <ActualiteFront />
        </section>
        <section className="flex-col-4_mbollo">
          <div className="rectangle-388"></div>
          <div className="">
         
          <div className="row">
          <div className="col-md-6 col-sm-4 col-lg-6">
          
          {/* <MblolloMobile /> */}
              <div className="mboolo-mooy-doole heading-1-small p-4"><React.Fragment>Mboolo,<br />Mooy doole.</React.Fragment></div>
            </div>
            <div className="col-md-6  col-sm-8 col-lg-6 ">
            <p className="lorem-ipsum-dolor-si-1 heading-5-small p-4">TOUBA CA KANAM est une association apolitique qui investit sa force, sa richesse,son intelligence au service de CHEIKH AHMADOU BAMBA KHADIM RASSOUL.</p>
            </div>
          </div>
          </div>
          {/* <p className="lorem-ipsum-dolor-si-2 body-large">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat nec blandit cum quis ipsum fringilla. Tincidunt ullamcorper dui leo dui eget. Porttitor mauris, integer nibh eu amet venenatis. Elementum egestas rhoncus, et, metus, amet, lorem amet amet. Sed lorem accumsan in nisi sed. Vitae fermentum iaculis dolor velit quam in convallis mauris. Ultricies sollicitudin vitae risus tempus viverra ..</p> */}
          <div className="">
            <div className="flex-row-12 not_view_on_mobile">
              <div className="frame-197 border-1px-green-tck ">
                <div className="image-container-2" style={{ backgroundImage: `url(${imageContainer})` }}>
                  {/* <img className="image" src={image7} alt="tck" /> */}
                  <img className="image" src={image82} alt="tck" />
                </div>
              </div>
              <div className="lu-mata-faggu-fagul heading-2-small">
              <React.Fragment>“Lu mata faggu fagul na nu ko (Sëriñ bi), te juboo na ci ak Boroomam ; lu mata ñaan ñaanal na ñu ko, te Boromam nangul na ko ko.<br />Kon legeeyël Sëriñ bi rek a fi nekk”</React.Fragment></div>
            </div>
            <div class="view_on_mobile_onlly_s_mount" style={{display:"none"}}>
            
            {/* <KadduMobile /> */}
          </div>
          </div>
        </section>
        <section className="container-tck ">
        <div className="flex-col-4_mbollo ">
        <div className="rectangle-387"></div>
        <CarouselEquipe />
        </div>
        </section>
        <CookieConsent
  location="bottom"
  buttonText="J'accepte!!"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#005210" }}
  buttonStyle={{ color: "#e89702", fontSize: "13px" }}
  expires={150}
>
  
Ce site utilise des cookies pour améliorer l'expérience utilisateur.{" "}
</CookieConsent>

      </div>
      <br></br>
    <FrontFooter />
  </div>;
}

export default FrontHome;