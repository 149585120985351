import React, { useContext , useEffect, useState} from 'react'
import './CarouselEquipe.css'

import FeatherIcon from 'feather-icons-react';
import Carousel, { consts } from 'react-elastic-carousel';
import ProjetItem from '../ProjetItem/ProjetItem';
import { NavLink } from 'react-router-dom';
import { DataContext } from '../../../redux/store/GlobalState';
import EquipeData from './EquipeData'
import image from '../../../assets/img/image-11@2x.png'
var CarouselEquipe = () => {
    const { state, dispatch } = useContext(DataContext)
    let { users } =state
    var [equipe, setequipe] = React.useState(true)
    var [equipe, setequipe] = useState([users]);
   
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)

        }, 2000);
    }, [users])
    console.log('testtsts',users);
    const breakPoints = [
        { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false, transitionMs: 500 },
        { width: 500, itemsToShow: 2, itemsToScroll: 1, pagination: false, transitionMs: 500 },
        { width: 768, itemsToShow: 3, itemsToScroll: 1, pagination: false, transitionMs: 500 },
        { width: 1200, itemsToShow: 4, itemsToScroll: 1, pagination: false, transitionMs: 500 }

    ];
    const items = [
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
        { id: 1, title: 'item #1' },
    ];

   
    return (
        <div className="component-carousel-projet">
            <div className="lquipe-touba-ca-kanam-1 heading-2-small">L’Équipe Touba ca Kanam</div>
            <div className="rectangle-375-3"></div>
                <div className="container-ahj flex-row-13 mb-5 pr-0 p-2 ">
                    <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500} itemPosition={consts.START} >
                        {users.map((item, index) => {

                            return (
                                <div>
                                    <div  item={item.id} key={index}  className="frame-184 border-1px-mist-gray mx-4 ">
                                    <img className="image-10" src={item.image} /> 
                                    </div>
                                    <div className="overlap-group-container">
                                        <div className="overlap-group13_1">
                                            <div className="serigne-habibou-falilou-mbacke-9 heading-6-small">{item.name}</div>
                                            <p className="responsable-moral-1-de-touba-ca-kanam-9 caption-small">
                                                {item.postion}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )  })
                        }
                    </Carousel>
                    
                </div>
        </div>
    )
}

export default  CarouselEquipe ;
