import React, { useContext,useState,useEffect } from 'react'
import './ActualiteListe.css'
import { Link, NavLink } from 'react-router-dom';
import { DataContext } from '../../../redux/store/GlobalState';
import Allarticle from '../../../assets/img/dashicons-arrow-right-alt2-3@2x.svg'
import CLOCK from '../../../assets/img/carbon-time@2x.svg'
import partage from '../../../assets/img/bi-share-fill@2x.svg'
import moment from 'moment';
import baseUrlFront from 'Http/frontend-base-url';
import frame1 from '../../../assets/img1/Frame1.png'
import frame from '../../../assets/img1/Frame.png'
import FrontFooter from '../frontFooter/frontFooter';
import {
    WhatsappShareButton,
  } from "react-share";
import FrontHeader from '../frontHeader/frontHeader';
import ImageLabel from "../../../assets/images/image_label.png";
import { iteratee } from 'lodash';

var ActualiteListe = () => {
    const { state, dispatch } = useContext(DataContext)
    let { actus_front,users} = state
    var [isPageLoading, setIsPageLoading] = React.useState(true);
    var [actufront, setlisteprojets] = useState([actus_front]);
   
    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
            window.scrollTo(0, 0)

        }, 2000);
    }, [actus_front])
    
    const url = (`${baseUrlFront}`)

   
    return (
        <div className="container-center-horizontal">
                <FrontHeader />
                <div class="container text-center py-0 py-3-lg py-1-sm">
                    <div class="row d-flex my-1 py-3-md py-0 py-1-sm">
                        <div class="col-md-6 col-lg-6 col-sm-12 py-3 py-3-lg py-1-sm">
                        <img className="frame-13" src={frame1}  alt="actualite"/>
                        </div>
                        <div class="col-md-6 col-lg-6 py-0 py-3-lg py-1-sm">
                        <img className="frame-132" src={frame}  alt="actualite"/>
                        </div>
                    </div>
                </div>
                {/* <div className="">
                    <div className="flex-col-4_projet pb-5 mb-5 pr-0">   
                        <div className="overlap-group8">
                        <div className="actualits-touba-ca-kanam heading-2-small">Actualités Touba ca kanam</div>
                        <div className="rectangle-385-1"></div>
                        <div className="flex-row-8">
                            {actus_front?.length > 0 ?
                            actus_front.map((item,index) => {
                                return (

                                    <div className="flex-col-2 col-md-4" key={index}>
                                        <div className="rectangle-385"></div>
                                        <div className="frame-152">          
                                        {
                                            item?.actu_images?.length > 0 ?
                                            item?.actu_images?.map((image) => {
                                                return (
                                                    <div className="overlap-group" style={{ backgroundImage: `url(${image.image})` }}>
                                                    <div className="rectangle-372"></div>
                                                </div>                                        )
                                            })
                                            :
                                            <div className="overlap-group" style={{ backgroundImage: ` url(${ImageLabel})` }}>
                                                <div className="rectangle-372"></div>
                                            </div> 
                                        }
                                            
                                        </div>
                                        <NavLink className="" to={`/detail-actualite/${item?.id}`}>
                                        <p className="ation heading-5-small">{item.title}</p>
                                        </NavLink>
                                        <p className="lorem-ipsum-dolor-si caption-small">{`${item.title.substring(0, 40)}...`}</p>
                                        <div className="pt-1">
                                        <img className="icon-clock" src={CLOCK}/>
                                        <span className="address">{item?.date}</span> */}
                                        {/* <span className="time-published pl-3">{moment(item?.created_at).lang('fr').fromNow()}</span> */}
                                        
                                        {/* <WhatsappShareButton url={url + "/" + item.id}
                                        quote={"Hello viens faire ton Barkelou!!"}
                                        hashtag="#TCK">
                                        <img className="bishare-fill-1" src={partage} />
                                        </WhatsappShareButton>
                                        </div>

                                    </div>

                                )} 
                                ) : <h1>Pas d'actuailites disponibles</h1>
                        }
                        </div>
                        
                        </div>
                    </div>
                </div> */}

                <section className="container-tck mb-4 py-5 ">
                <div className="col-xl-12 col-lg-12 col-md-12 bloc-detail">
                <div className=" pt-0">
                    <div className=" pt-0">
                        <div className="d-flex mb-5 mt-3">
                        <h3 className="h3-theme mb-0">Actualités Touba ca kanam </h3>
                        </div>
                    </div>
                    <div className="row ">
           
           {
           actus_front?.length > 0 ?
                actus_front.map((item,index) => {
                    return (
                     <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
                         <div className="">         
                            <div className="card">
                            {
                                item?.actu_images?.length > 0 ?
                                item?.actu_images?.filter(x => x.display_order === parseInt(1)).map((image) => {
                                    return (
                            <img width="319" height="198" src={image.image ?  image?.image : ImageLabel} className="card-img-top" alt="Projet Item" />
                                    )
                                })
                                :
                                ''
                            }
                        
                            <div className="card-body">
                                <NavLink className="" to={`/detail-actualite/${item?.id}`}>
                                <h5 className=" ation heading-5-small">{`${item.title.substring(0, 40)}...`}</h5>
                                </NavLink>
                                <p className="lorem-ipsum-dolor-si caption-small">{`${item.description.substring(0, 100)}...`}</p>
                                <div className="pt-5">
                                    <div className="pt-1">
                                    <img className="icon-clock" src={CLOCK}/>
                                    <span className="address">{moment(item?.date).lang('fr').fromNow()}</span>
                                        <WhatsappShareButton url={url}
                                        quote={"Hello viens faire ton Barkelou!!"}
                                        hashtag="#TCK">
                                        <img className="bishare-fill-1" src={partage} />
                                        </WhatsappShareButton>
                                    </div>
                                </div>

                            </div>
                        </div>
                         </div>
                     </div>
                           
                    )} 
                    ) : <h1>Pas d'actuailites disponibles</h1>
            }
                     
                     </div>
                 </div>  
                 </div> 
         </section>


            <FrontFooter  />
        </div> 
    )
}

export default ActualiteListe;
