import React, { Component } from "react";
import "./frontLogin.css";
import { Link, NavLink, use } from "react-router-dom";
// import PersonIcon from "@material-ui/icons/Person";
// import LockIcon from "@material-ui/icons/Lock";
import FeatherIcon from 'feather-icons-react';
import { ToastContainer, toast } from 'react-toastify';
// import isAuthenticate from './../modules/Secure/Secure'
// import ResetPasswordModal from './ResetPasswordModal'
// import EmptyHeader from './EmptyHeader';
import axios from 'axios';
import api from "../../../Http/backend-base-url";
import Swal from 'sweetalert2'
// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from 'react-google-login';
// import TwitterLogin from "react-twitter-login";
// import facebookClientId from '../../../Http/facebookClientId';
// import googleClientId from '../../../Http/googleClientId';
// import twitterClientId from '../../../Http/twitterClientId';
// import twitterkeysecret from '../../../Http/twitterkeysecret';




// var frontLogin = (props) => {
export default class frontLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      isLoginInProgress: false,
      isSuccessLogin: false,
      isErrorLogin: false,
      is_empty_email: false,
      is_empty_password: false,
      isInvalidCredential: false,
      token: window.sessionStorage.getItem('userToken'),
      requestConfig: {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('userToken')}` }
      },
      userID: window.sessionStorage.getItem('userID'),
      userType: window.sessionStorage.getItem('userType'),

    }
    console.log(this.state.userType, this.state.userId, "user tesst ");

    this.handleChange = this.handleChange.bind(this)
    this.onSubmitLogin = this.onSubmitLogin.bind(this)
  }

  componentDidMount() {
    // this.onTestUserConnectedStatus()
  }

  onTestUserConnectedStatus = () => {
    // var isNotConnected = isAuthenticate()
    // var userType = this.state.userType
    // var isFirstConnexion = window.sessionStorage.getItem('userFirstConnexion')

    // if (!isNotConnected && userType === 'standard') {
    //     if (isFirstConnexion === true) {
    //         window.location = '/welcome'
    //     } else {
    //         window.location = '/tous-les-modules'
    //     }
    // } else if (!isNotConnected && userType === 'admin') {
    //     window.location = '/tableau-de-bord'
    // }
  }

  handleChange(e) {
    let fields = this.state.fields
    fields[e.target.name] = e.target.value

    if (e.target.name === 'email') {
      fields[e.target.name] = e.target.value.toLowerCase();
    }

    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  onValidateLoginForm() {
    let fields = this.state.fields
    let errors = this.state.errors
    var isValidForm = true

    if (!fields['email']) {
      isValidForm = false
      this.setState({
        is_empty_email: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_email: false
        })
      }, 5000);

      errors['email'] = 'Ce champ est obligatoire'
    }
    if (!fields['password']) {
      isValidForm = false
      this.setState({
        is_empty_password: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_password: false
        })
      }, 5000);

      errors['password'] = 'Ce champ est obligatoire'
    }

    this.setState({
      errors: errors
    })

    return isValidForm;
  }

  onSubmitLogin(e) {
    e.preventDefault()
    if (this.onValidateLoginForm()) {
      this.setState({
        isLoginInProgress: true
      })

      // var api = this.state.api
      var data = this.state.fields


      axios.post(api + 'auth/get-token', data)
        .then(response => {
          //console.log(response)
          if (response.status === 200) {
            if (response.data.status === 'failure') {
              toast.error(response.data.message)

              this.setState({
                isInvalidCredential: true,
                isLoginInProgress: true
              })

              setTimeout(() => {
                this.setState({
                  isInvalidCredential: false,
                  isLoginInProgress: false
                })
              }, 5000);
            } else {
              var token = response.data.token
              window.sessionStorage.setItem('userToken', token)
              let requestConfig = {
                headers: { Authorization: `Bearer ${token}` }
              }
              this.onGetConnectedUserInfos(requestConfig)
            }
          } else if (response.data.success === false && response.data.invalid_credential === true) {
            // toast.error("Vos identifiants de connexion ne sont pas valides !")
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Vos identifiants de connexion ne sont pas valides !',
              showConfirmButton: true,
              iconColor: '#068923',
              confirmButtonColor: '#068923',
            })
            this.setState({
              isInvalidCredential: true
            })

            setTimeout(() => {
              this.setState({
                isInvalidCredential: false
              })
            }, 5000);
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.',
            showConfirmButton: true,
            iconColor: '#068923',
            confirmButtonColor: '#068923',
          })
          // toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
          this.setState({
            isErrorLogin: true,
            isLoginInProgress: false
          })

          setTimeout(() => {
            this.setState({
              isErrorLogin: false
            })
          }, 5000);
        })
    }
  }
  responseFacebook = (response) => {
    console.log('facebook', response);
    if(response){
    var fd = new FormData();
    fd.append('last_name', response.name)
    fd.append('email', response.email)
    fd.append('user_type', "standard")
    fd.append('first_name', response.name)
    // fd.append('phone', "non communique ")
    axios
      .post(`${api}auth/registersocial/`, fd)
      .then(async (res) => {
        console.log('responsnsnsns', res);
        var token = res.data.token
        window.sessionStorage.setItem('userToken', token)
        let requestConfig = {
          headers: { Authorization: `Bearer${token}` }
        }
        this.onGetConnectedUserInfos(requestConfig)
      })
      .catch((e) => {
        console.log('errorrrooo', e.res);
      })
    }
  }

  responseGoogle = (response) => {
    console.log('facebook', response);
    if(response){
    var fd = new FormData();
    fd.append('last_name', response.profileObj.givenName)
    fd.append('email', response.profileObj.email)
    fd.append('user_type', "standard")
    fd.append('first_name', response.profileObj.familyName)
    // fd.append('phone', "non communique ")
    axios
      .post(`${api}auth/registersocial/`, fd)
      .then(async (res) => {
        console.log('responsnsnsns', res);
        var token = res.data.token
        window.sessionStorage.setItem('userToken', token)
        let requestConfig = {
          headers: { Authorization: `Bearer ${token}` }
        }
        this.onGetConnectedUserInfos(requestConfig)
      })
      .catch((e) => {
        console.log('errorrrooo', e);
      })
    }
  }
  authHandler = (err, data) => {
    console.log('babab', err, data);
    var fd = new FormData();
    fd.append('last_name', data.screen_name)
    fd.append('email', data.email)
    fd.append('user_type', "standard")
    //fd.append('first_name', data.screen_name)
    // fd.append('phone', "non communique ")
    axios
      .post(`${api}auth/registersocial/`, fd)
      .then(async (res) => {
        console.log('responsnsnsns', res);
        var token = res.data.token
        window.sessionStorage.setItem('userToken', token)
        let requestConfig = {
          headers: { Authorization: `Bearer ${token}` }
        }
        this.onGetConnectedUserInfos(requestConfig)
      })
      .catch((e) => {
        console.log('errorrrooo', e.res);
      })

  };

  onGetConnectedUserInfos = (config) => {
    // var api = this.state.api
    axios.get(api + 'me/', config)
      .then(response => {
        //console.log(response)
        this.setState({
          isLoginInProgress: false
        })
        if (response.status === 200) {
          window.sessionStorage.setItem('userID', response.data.data.id)
          window.sessionStorage.setItem('userInfos', JSON.stringify(response.data.data))
          window.sessionStorage.setItem('userFirstName', response.data.data.first_name)
          window.sessionStorage.setItem('userLastName', response.data.data.last_name)
          window.sessionStorage.setItem('userFullname', response.data.data.first_name + ' ' + response.data.data.last_name)
          window.sessionStorage.setItem('userType', response.data.data.user_type)
          window.sessionStorage.setItem('UserCreate', response.data.data.date_joined)
          window.sessionStorage.setItem('userFirstConnexion', response.data.data.first_connexion)
          var userType = response.data.data.user_type
          var isFirstConnexion = response.data.data.first_connexion
          //console.log(response.data.data)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Connexion réussie !',
            showConfirmButton: true,
            iconColor: '#068923',
            confirmButtonColor: '#068923',
          })
          // toast.success("Connexion réussie !")
          this.setState({
            isSuccessLogin: true
          })
          setTimeout(() => {
            this.setState({
              isSuccessLogin: false,
            })
            window.location = '/'
          }, 5000);
          console.log('userType', userType)
        }
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.',
          showConfirmButton: true,
          iconColor: '#068923',
          confirmButtonColor: '#068923',
        })
        // toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
        this.setState({
          isErrorLogin: true,
          isLoginInProgress: false
        })

        setTimeout(() => {
          this.setState({
            isErrorLogin: false
          })
        }, 5000);
      })
  }

  render() {

    return (
      <div className="component-front-login" >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex px-0">
              <div className="formLogin-tck-front p-4">
                {/* <div className="row d-flex justify-content-center">
                  <div className="col-md-6">
                    <FacebookLogin
                      appId={facebookClientId}
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      icon="fa-facebook"
                      textButton="Connecter avec Facebook"
                    />
                  </div>
                </div>
                <br></br>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-6">
                    <GoogleLogin
                      clientId={googleClientId}
                      buttonText="Connecter avec GOOGLE"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                    />
                  </div>
                </div>
                <br></br>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-6">
                    <TwitterLogin
                      authCallback={this.authHandler}
                      consumerKey={twitterClientId}
                      consumerSecret={twitterkeysecret}
                    />
                  </div>
                </div> */}
                <h5 className="title-inscrivez-vous mt-5 text-center">Connectez-vous</h5>
                <div class="row my-3 ml-5">
                  {/* <div class="col-md-4">
                    <FacebookLogin
                      appId={facebookClientId}
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      icon="fa-facebook"
                      textButton=""
                      cssClass="facebook-social-style"
                    />
                  </div>
                  <div class="col-md-4">
                    <GoogleLogin
                      clientId={googleClientId}
                      buttonText="Connecter avec GOOGLE"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      render={renderProps => (
                        <i onClick={renderProps.onClick} className="fa fa-google google-social-style"></i>
                      )}
                    />
                  </div>
                  <div class="col-md-4">
                    <TwitterLogin
                      authCallback={this.authHandler}
                      consumerKey={twitterClientId}
                      consumerSecret={twitterkeysecret}
                      children={<i className="fa fa-twitter twitter-social-style"></i>}
                    />
                  </div>*/}
                </div> 
                {/* <h5 className="text-center mt-3 font-weight-bold">OU</h5> */}
                <form id="login-form">
                  <div className="p-4 mt-3 position-formLogin">
                    <div className="input-group my-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text span-input-icon" id="basic-addon1">
                                    <FeatherIcon className="icon-input" icon="user" />
                                </span>
                            </div>
                            <input name="username" id="username" onChange={(e) => this.handleChange(e)} type="text" className="form-control input-with-icon" placeholder="Identifiant" />
                            {this.state.is_empty_username &&
                                <div className="alert alert-danger gfa-alert-danger"> {this.state.errors.username} </div>
                            }
                        </div>
                        <div className="input-group my-4">
                            <div className="input-group-prepend">
                                <span className="input-group-text span-input-icon" id="basic-addon1">
                                    <FeatherIcon className="icon-input" icon="lock" />
                                </span>
                            </div>
                            <input name="password" id="password" onChange={(e) => this.handleChange(e)} type="password" className="form-control input-with-icon" placeholder="Mot de passe" />
                            {this.state.is_empty_password &&
                                <div className="alert alert-danger gfa-alert-danger"> {this.state.errors.password} </div>
                            }
                        </div>
                  </div>

                  <div className="dis-flex justify-content-end pr-4 m-b-4 ">
                    {/* <Link className="mdpOublie-form-login" to="/front-login">
                      Mot de passe oublié ?
                    </Link> */}
                    <Link className="mdpOublie" to="/demande-modification-password" >Mot de passe oublié ?</Link>

                  </div>

                  <div className="row d-flex justify-content-around py-5">
                    <div className="col-md-4">
                      {/* <NavLink
                        to="/app/accueil"
                        className="btn-theme-inscription btn"
                      >
                        Connexion
                      </NavLink> */}
                      {this.state.isLoginInProgress === false ?
                        <button type="submit" onClick={(e) => this.onSubmitLogin(e)} className="btn-connexion">Connexion</button>
                        :
                        <button className="btn-theme-inscription btn in-progress-btn">
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      }
                    </div>
                  </div>

                  <div lassName="col-md-5 offset-md-4 ">
                    <p className="inscrivez-vous mt-3">
                      <font className="color-part1">
                        Vous n’avez pas de compte? Inscrivez-vous
                      </font>
                      <font className="color-part2">
                        {" "}
                        <Link className="color-part2" to="/inscription">
                          ici
                        </Link>{" "}
                      </font>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex px-0">
              <div className="welcome-tck-front bienvenue-style">
                <h6 className="title-welcome ml-2"></h6>
                <div className=" my-4">
                    <p className="text-tck-login pb-3">
                    Bienvenue sur la plateforme <br />
                    digitale de Touba ça Kanam                    
                    </p>
                </div>
                <h1 className="text-welcome-tck mt-4 pb-3"> Laaj lu ñu man,
                  Def ko fu ñu xam.</h1>
                <p className="text-sous-title mt-4">
                Jaamu Yàlla ci jaami Yàlla yi
                </p>
                
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isSuccessLogin || this.state.isErrorLogin || this.state.isInvalidCredential ?
            <ToastContainer /> : ''
        }
      </div >
    );
  }
};
