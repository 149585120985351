import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import api from "../../Http/backend-base-url"
import TckLogo from "../../assets/images/tck-logo.jpeg";
import { Link } from 'react-router-dom';

export default class ModifierPassword extends Component {

    render() {
        return (
            <>
                <div className="container">
                    <div className="row g-0 my-5 login-page-row " >
                        <div className="col-md-6 offset-md-3 right-side-col  d-flex justify-content-center align-items-center border-0 rounded-lg" style={{ background: '#ffffff' }}>
                            <img src={TckLogo} alt="Logo TCK" height={50} />
                        </div>
                        <div className="col-md-6 offset-md-3 right-side-col justify-content-center align-items-center border-0 rounded-lg" >
                            <div className="reset-form-container">
                                <div className="login-form-introduction d-flex justify-content-center">
                                    <h3 className="login-title-label">Récupération de  mot de passe </h3>
                                </div>
                                <form id="login-form-reset" className="reset-request-form">
                                    <div className=" my-4">
                                        <p className="text-comment pb-3">
                                            Veuillez entrer votre email <br />
                                            Nous vous enverrons un lien pour modifier le mot de passe
                                        </p>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text span-input-icon" id="basic-addon1">
                                                <FeatherIcon className="icon-input" icon="mail" />
                                            </span>
                                        </div>
                                        <input name="email" id="email"  type="email" className="form-control input-with-icon" placeholder="Adresse mail" />
                                    
                                    </div>
                                    <div className="row submit-btn-row py-2 my-2">
                                        <div className="col-md-8 offset-md-2 submit-btn-col">
                                                <button type="submit" onClick={(e) => this.onSubmit(e)} className="btn-password">SOUMETTRE</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="dis-flex justify-content-end m-b-40">
                                <Link className="text-muted" to="/" >Connectez-vous</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

