import axios from 'axios';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
// import api from './../../http/global-vars'
// import axios from 'axios'

export default class LineChart extends Component {
    
    onGetChartDatesInfos = () => {
        var dates = [
            'Dec',
            'Jan',
            'Fev',
            'Mar',
            'Avr',
            'Mai',
            'Jui',
            'Juil',
            'Aou',
            'Sep',
            'Oct',
            'Nov',
        ];

        return dates;
    }

    // onGetChartDataCountOne = () => {
    //     var counting = [
    //         2055, 908, 1987, 3035, 1204, 402, 1258, 2055, 908, 1987, 3035
    //     ];

    //     return counting
    // }

    onGetChartDataCountTwo = () => {
        var counting = [
            875, 908, 756, 4267, 987, 5402, 678, 4325, 1345, 9076, 4568
        ];

        return counting;
    }



    render() {
        let data = {
            labels: "infos",
            datasets: [{
                type: 'line',
                label: 'Suivie Barkelou',
                data: [1,2,23,4,5,6,56,8,9,10,11,12],
                fill: false,
                backgroundColor: '#1f4c27',
                borderColor: '#1f4c27',
                hoverBackgroundColor: '#082E72',
                hoverBorderColor: '#082E72',
                yAxisID: 'y-axis-1'
            },

            {
                type: 'line',
                label: 'Suivie JAYANTE',
                data: [1,2,3,45,5,6,7,89,9,134,11,12],
                fill: false,
                backgroundColor: '#b0afad',
                borderColor: '#b0afad',
                hoverBackgroundColor: '#71B37C',
                hoverBorderColor: '#71B37C',
                yAxisID: 'y-axis-1'
            }
            ]
        };

        const options = {};
        options.scales = {};
        options.scales.yAxes = [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
            },
           
        ];


        return (
            <div className="chart-item-container">
                <div className="row mb-2">
                    <div className="col-md-6 mb-2">
                        <h2 className="chart-item-title">Suivie - <span className="fw-300"> Barkelou ET JAYANTE </span> </h2>
                    </div>
                    {/* <div className="col-xl-4 offset-xl-2 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex">
                        <select className="form-select form-select-line-chart ml-md-auto" aria-label="Default select example">
                            <option selected>Janvier</option>
                            <option value="1">Semaine du 07/01/2021</option>
                            <option value="2">Semaine du 14/01/2021</option>
                            <option value="3">Semaine du 21/01/2021</option>
                        </select>
                    </div> */}
                </div>
                <Bar
                    data={data}
                    options={options}
                />
            </div>
        );
    }
}