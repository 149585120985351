import React ,{ useState} from 'react';
import './frontFooter.css';
// import Logo from '../../../images/ahj-logo.png';
// import Facebook from '../../../images/icons/facebook.png';
// import Twitter from '../../../images/icons/twitter.png';
// import Instagram from '../../../images/icons/instagram.png';
// import Linkedin from '../../../images/icons/linkedin.png';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import axios from 'axios';
import api from 'Http/backend-base-url';
import mockupTck2 from '../../../assets/mockup-tck-2@1x.png';
import AppPlay from '../../../assets/imageapp.png';
import google from '../../../assets/googleplaydown.png';

var FrontFooter = (props) => {
  var validationSchema = Yup.object().shape({
    email: Yup.string().required('Ce champ est obligatoire'),
    name: Yup.string().required('Ce champ est obligatoire'),
    message: Yup.string().required('Ce champ est obligatoire'),
   
  })


  const [InProgress, setInProgress] = useState(false);
  const formOptions = { resolver: yupResolver(validationSchema) };
 

const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
const { errors } = formState;

const onSubmitContactUS = async (data) => {
      
  setInProgress(true)
  try {
  var formData = new FormData();
      if (data.email) {
        formData.append('email', data.email)
        formData.append('name', data.name)
        formData.append('message', data.message)
        axios.post(api + 'api/contactus/', formData).then((res) => {  
          console.log('paimemme card',res.data);
          if(res.data === 200 | 201){
            Swal.fire({
                position: 'center',
                icon: 'info',
                title: "Jërëjëf ! Votre message est envoyé.",
                showConfirmButton: true,
                iconColor: '#1f4c27',
                confirmButtonColor: '#1f4c27'
              })
              setTimeout(() => {
              setInProgress(false);
                        reset()            
        }, 3000);
          }
          
              // setInProgress(false);
          // reset()
      }).catch((error) => {
          console.log(error.response);

        if (error.response.status === 400) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "Oups",
            showConfirmButton: true,
            iconColor: '#1f4c27',
            confirmButtonColor: '#1f4c27'
          }).then((result) => {
            if (result.isConfirmed) {
              setInProgress(false);

            }
          })
        }
        if (error.response.status === 500) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "Oups! Veuillez réessayer !",
            showConfirmButton: true,
            iconColor: '#1f4c27',
            confirmButtonColor: '#1f4c27'
          }).then((result) => {
            if (result.isConfirmed) {
              setInProgress(false);

            }
          })
          
        }
        if (error.response.status === 403) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: "Oups  ! Veuillez réessayer",
              showConfirmButton: true,
              iconColor: '#1f4c27',
              confirmButtonColor: '#1f4c27'
            }).then((result) => {
              if (result.isConfirmed) {
                setInProgress(false);

              }
            })
            
          }
      })
        
      }
    }catch (error) {
      console.log("error.response.status", error.response);
      Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Erreur...',
          showConfirmButton: true,
          iconColor: '#1f4c27',
          confirmButtonColor: '#1f4c27'
      }).then((result) => {
          if (result.isConfirmed) {
              setInProgress(false);
              reset()
          }
      })
  }
}
  return <footer className="component-front-footer">
           
      <div className="container-tck">
        <div className="row mx-0 d-flex  align-items-center  social-networking mt-5 mb-3">
          <div className="col-xl-6 col-lg-6 col-md-6">
          <form  onSubmit={handleSubmit(onSubmitContactUS)} >

              <div className="row">
                <div className="input-group my-4 col-md-6">
                  <label className='label opensans-semi-bold-white-20px p-1'>Email *: 
                    <input name="email" id="username" type="email" className="form-control input-with-icon" placeholder="email"
                     {...register('email')}
                     />
                     {errors?.email && (
                       <div className="alert alert-danger gfa-alert-danger">
                         {' '}
                         {errors?.email?.message}{' '}
                       </div>
                     )}
                    </label>
                </div>
                <div className="input-group my-4 col-md-6">
                    <label className='label opensans-semi-bold-white-20px p-1'>Prenom et Nom *: 
                      <input name="name" id="password"  type="text" className="form-control input-with-icon" placeholder="Prenom et Nom" 
                      {...register('name')}
                      />
                      {errors?.name && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {' '}
                          {errors?.name?.message}{' '}
                        </div>
                      )}   
                    </label>
                </div>
                <div className="input-group my-2 col-md-12">
                  <label className='label opensans-semi-bold-white-20px p-1'>Message *: 
                    <textarea name="message"  {...register('message')} cols="70" rows="10" className="form-control input-with-icon" placeholder="Votre Texte"></textarea>
                      {errors?.message && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {' '}
                          {errors?.message?.message}{' '}
                        </div>
                      )}   
                  </label>
                </div>
              </div>
               {/* <div className="button34">
                  <div className="button-filledlm-fals">
                    <div className="button-32 opensans-semi-bold-white-16px">
                   
                    </div>
                  </div>
              </div> */}

              <div className="contribuer-bouton-2-1">
                  {!InProgress ? (
                      <button className="contribuer-1 caption-small-semi-bold"  type="submit"> Envoyer Message</button>
                  ) : (
                      <span className="contribuer-1 crud-submit-btn12">En cours&nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                      </span>

                  )}                        
              </div>

              </form>
            </div>
          <div className="col-xl-6 col-lg-6 col-md-6 ">
          <img className="mockup-tck-2" src={mockupTck2} alt="tck"/>
          <div className="row ">
              <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12  p-1">
                <a href="https://play.google.com/store/apps/details?id=com.tuubaCaKanam" target="_blank" rel="noreferrer noopener">
                <img className="mockup1" src={google} alt="tck"/>
                </a>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-6  col-sm-12 p-3 ">
              <a href="https://apps.apple.com/fr/app/touba-ca-kanam/id1642114266" target="_blank" rel="noreferrer noopener">
              <img className="mockup" src={AppPlay} alt="tck" height={86}/>
              </a>
              </div>
          </div>
          </div>
          
        </div>
      </div>
      <div className="row mx-0 end-footer py-md-4 py-3 d-flex justify-content-center align-items-center">
        <div className="col-md-6 offset-md-3 mt-lg-0 mt-5 px-0 d-lg-flex">
          <p className="copyright">©2022 All Rights Reserved. touba ca kanam &nbsp;by  &nbsp;</p>
        
          <a href="https://sen-gt.com/" target="_blank" rel="noreferrer noopener">
          <p className="copyright">Sen-gt.</p>
        </a>
          
        </div>
      </div>
  </footer>;
}

export default FrontFooter;