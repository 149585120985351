import { action } from '@testing-library/react'
import { ACTIONS } from './Actions'

const reducers = (state, action) => {

    switch (action.type) {

        case ACTIONS.AUTH:
            return {
                ...state,
                auth: action.payload
            }
       
        /*CREATE_LIST_PROJECT*/
        case ACTIONS.CREATE_LIST_PROJECT:
            return {
                ...state,
                list_projects: action.payload
            }
            case ACTIONS.PROJECT_EDIT:
                return{
                    ...state,
                    list_project: action.payload
                }
          

        /*CREATE_LIST_PROJECT*/
        
        /*CATEGORY_PROJECTS*/
        case ACTIONS.CATEGORY_PROJECTS:
            return {
                ...state,
                category_projects: action.payload
            }
        case ACTIONS.CATEGORY_PROJECTS_CREATE:
            return {
                ...state,
                category_projects_create: action.payload
            }
        case ACTIONS.CATEGORY_PROJECTS_EDIT:
                return {
                    ...state,
                    category_project: action.payload
                }
        //Recuperation users
        case ACTIONS.ALL_USERS:
            return {
                ...state,
                users: action.payload
            }
        case ACTIONS.ACTUALITES:
                return {
                    ...state,
                    actus: action.payload 
                }
        case ACTIONS.PROJECT_FRONT:
            return {
                ...state,
                liste_projects_front: action.payload 
            }
        case ACTIONS.ACTUALITES_FRONT:
        return {
            ...state,
            actus_front: action.payload 
        }
        case ACTIONS.CATEGORY_PROJECTS_FRONT:
            return {
                ...state,
                category_projects_front: action.payload
            }
        case ACTIONS.DETAILS_PROJECTS_TO_FRONT:
                return {
                    ...state,
                    details_projects_front: action.payload
                }
        case ACTIONS.ID_PROJET_CONTRIBUER:
            return {
                ...state,
                id_projet_contribuer: action.payload
            }
            case ACTIONS.SLIDER:
                return {
                    ...state,
                    slider: action.payload
                }
        default:
            return state;
    }
    
}

export default reducers
