import React, { useContext, useEffect } from 'react';
import './frontProjet.css';
import FrontHeader from '../frontHeader/index';
// import FrontFooter from '../frontFooter/index';
import HomeBanner from '../HomeBanner/HomeBanner';
import ActualiteFront from '../ActualiteFront/ActualiteFront';
import imageContainer from '../../../assets/img/image-6@2x.png'
import  image7  from '../../../assets/img/image-7-6@2x.png' 
import  image82  from '../../../assets/img/image-8-3@2x.png' 
import CarouselEquipe from '../CarouselEquipe/CarouselEquipe';
import Direction from '../../../assets/img/dashicons-arrow-right-alt2-3@2x.svg'
import FrontFooter from '../frontFooter';
import ProjetBanner from '../ProjetBanner/ProjetBanner';


var FrontProjet = (props) => {

  
  return <div className="">
    
    <FrontHeader />
      <div className="content-view-start">
        <ProjetBanner />
      </div>
      <br></br>
    {/* <FrontFooter /> */}
  </div>;
}

export default FrontProjet;