import React from 'react'
import '../ContributionBarkelou/Contribution.css'
import FrontHeader from '../frontHeader/frontHeader'
import IMG from "../../../assets/img1/group-1710@2x.svg"


import FrontFooter from '../frontFooter/frontFooter';


var PageError = (props) => {
 
    return (
        <div >
        <FrontHeader />

            <section className="container-tck ">
            <div class="row">
                <div class="col-md-1 col-lg-1 col-sm-2"></div>
                    <div class="col-md-10 col-lg-10 col-sm-12">
                        <div class="row  my-5 py-3-md py-5 py-1-sm bloc-detail my_row_radius">
                            <div class="col-md-12 col-lg-12 col-sm-12 py-3 py-3-lg py-1-sm">
                                <h1 className="laaj-lu-u-man-def-ko-fu-u-xam2 display">Une erreur s'est produite !</h1>
                                <p className="jaamul-ylla-ci-jaam2 caption-large">Veuillez réesayer à nouveau.</p>  
                            </div>
                            {/* <div class="col-md-12 col-lg-4 py-3  py-3-lg py-1-sm col-sm-12">
                                <img className="group-1710_other" src={IMG} alt="tck"/>
                            </div> */}
                   
                        </div>
                    </div>
               
            </div>
            </section>
            <FrontFooter  />
        </div>

    // </div> 
    )
}

export default PageError;
