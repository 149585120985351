// DEV URL
//var api = "https://tck.sen-gt.com/";
//var api = "http://195.154.57.83:8000/";
         var api = "https://tckapi.sen-gt.com/"; 
         


// DEMO API BASE URL
// var api = ''

// ONLINE API BASE URL
// var api = "";

export default api;