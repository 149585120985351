import React, { useContext } from 'react'
import './SuivieProjet.css'
import { NavLink } from 'react-router-dom';
import { DataContext } from '../../../redux/store/GlobalState';
import IMGUSER from '../../../assets/img/mdi-account@2x.svg'
import IMGJAM from '../../../assets/img/jam-tree-f@2x.svg'
import IMGGROUP from '../../../assets/img/fa-group@2x.svg'
import IMGSOLID from '../../../assets/img/fa-solid-hospital@2x.svg'

var SuivieProjet = () => {
    const { state, dispatch } = useContext(DataContext)
    let { list_projects_front } = state




    return (
        <>
            <div className='container mb-5' style={{ backgroundColor: '#ffffff', marginTop: '8%',position:'relative' }}>
            <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12"> 
            
                <div class="d-flex justify-content-around p-5">
                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <h3 className="text-sm  heading-2-small">Baarkelu en 2022</h3>
                        <p className="text-sm caption-large caption-large-color">When people help people change happen. What have you given to TCK ?</p>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6"> <h4 className="x1200000000-fcfa heading-2-small">1.200.000.000 FCFA</h4></div>
                </div>
                <div class="d-flex justify-content-center py-5" style={{ marginLeft: '10%', marginTop: '-73px' }}>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 media-phone'>
                        <div className="overlap-group17-1">
                            <div className="rectangle-375"></div>
                            <div className="rectangle-376"></div>
                            <div className="rectangle-377 border-1px-green-tck"></div>
                            <div className="rectangle-378 border-1px-green-tck"></div>
                            <div className="rectangle-379 border-1px-green-tck"></div>
                            <div className="rectangle-380 border-1px-green-tck"></div>
                        </div>
                        <div className="number-container">
                            <div className="number heading-6-small">2018</div>
                            <div className="number-1 caption-large">2019</div>
                            <div className="number-2 caption-large">2020</div>
                            <div className="number-3 caption-large">2021</div>
                            <div className="number-4 caption-large">2022</div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="d-flex justify-content-around py-5 px-3">
                        <div class="row">
                            <div className='col-md-3 col-sm-6 col-lg-3'>
                                <div className="frame-185">
                                    <img className="icon-user" src={IMGUSER} />
                                    <div className="frame-6-0">
                                        <div className="text-1 valign-text-middle heading-2-small">1000+</div>
                                        <div className="membres-actifs valign-text-middle body-large">Membres actifs</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-lg-3'>
                                <div className="frame-185">
                                    <img className="fa-solidhospital" src={IMGSOLID} />
                                    <div className="frame-6-1">
                                        <div className="text-2 valign-text-middle heading-2-small">6+</div>
                                        <div className="poste-de-sant valign-text-middle body-large">Poste de santé</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-lg-3'>
                                <div className="frame-185">
                                    <img className="jamtree-f" src={IMGJAM} />
                                    <div className="frame-6-2">
                                        <div className="text-3 valign-text-middle heading-2-small">800+</div>
                                        <div className="arbres-plants valign-text-middle body-large">Arbres plantés</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-lg-3'>
                                <div className="frame-185">
                                    <img className="fagroup" src={IMGGROUP} />
                                    <div className="frame-6-3">
                                        <div className="text-4 valign-text-middle heading-2-small">200+</div>
                                        <div className="familles-aides valign-text-middle body-large">Familles aidées</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="d-flex justify-content-center py-5 px-3">
                    <div className="button-1">
                        <div className="button-filledmm-false-false-true-default">
                            <NavLink className="button inter-semi-bold-white-16px-barkelou " to="/contribution">Barkeelou</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </>
    )
}

export default SuivieProjet;
