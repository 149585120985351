import React, { useContext, useEffect, useState } from 'react'
import './DetailActu.css';
import FrontHeader from '../frontHeader/index';
import FrontFooter from '../frontFooter/index';
import { DataContext } from '../../../redux/store/GlobalState';
import image from '../../../assets/img/image-8-4@1x.png';
import FeatherIcon from "feather-icons-react";
import axios from 'axios';
import api from 'Http/backend-base-url';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
    WhatsappShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";
import twitter from '../../../assets/img1/img/akar-icons-twitter-fill@2x.svg';
import backimage from '../../../assets/images/slider.png'
import facebook from '../../../assets/img/fa-facebook-square@2x.svg';
import instagram from '../../../assets/img/bxl-instagram-alt@2x.svg';
var DetailActu = () => {
    const { state, dispatch } = useContext(DataContext)
    // let { details_projects_front, id_projet_contribuer } = state
    var [details_actualites_front, setDetailsActualite] = useState([])
    // var [CategoryName, setCategoryName] = useState()

    var { id } = useParams()
    useEffect(() => {
        axios.get(`${api}api/actus/front/${id}`).then((res) => {
            setDetailsActualite(res.data)
            window.scrollTo(0, 0)

        })
      
        // var category_id = DetailsProjet.category
        // axios.get(`${api}categoryproject/${category_id}`, requestConfig).then((res) => {
        //   setCategoryName(res.data)
        // })
      }, [id])
      const url = `http://tck-web.sen-gt.com/actus/front/${id}`

      console.log('dataa',details_actualites_front);
    return (
        <div className="component-detail-actu">
            <FrontHeader />
            <div className="mt-5">
                <div className="container-aem">
                    <h3 className="title-actualite pb-3">Actualité</h3>
                    <div className="container">
                    <div className="row pb-5">
                        <div className=" col-md-12 bloc-detail">
                            <div className="row pb-5">
                                <div className=" col-md-12 col-sm-12 col-ld-8  p-3 pb-3">
                                    { 
                                    details_actualites_front?.actu_images?.length > 0 ?
                                    details_actualites_front?.actu_images.filter(x => x.display_order === parseInt(1)).map((item, index) => {
                                        return (
                                    <div className="bloc-detail-actualite">
                                    <img src={item.image} alt="Detail actu" className="img-qsn img-actu-dtls"/>
                                    </div>
                                    )}):
                                        <div className="bloc-detail-actualite">
                                        <img src={image} alt="Detail actu" className="img-qsn img-actu-dtls" />
                                        </div>
                                        } 
                                    <div className="py-3 ation tourne-de-massification-touba-ca-kanam_details">
                                    {details_actualites_front.title} 
                                    </div>
                                    <div className="py-3">
                                        <p className="text-paragraph" dangerouslySetInnerHTML={{ __html: details_actualites_front?.description && details_actualites_front?.description !== "undefined" ? details_actualites_front?.description : "Non Communiqué" }}>
                                        </p>
                                    </div>
                                    <div>
                                    <div className="flex-row-22">
                                            <div className="partager-sur-1 body-small">Partager</div>
                                            <TwitterShareButton url={url}
                                            quote={"Hello pouvez-vous votez pour mon projet !!"}
                                            hashtag="#TOUBACAKANAM">
                                            <img className="icon-twitter-1" src={twitter} alt="twitter"/>
                                            </TwitterShareButton>
                                            <FacebookShareButton url={url}
                                            quote={"Hello pouvez-vous votez pour mon projet !!"}
                                            hashtag="#TOUBACAKANAM">
                                            <img className="icon-facebook-1" src={facebook}  alt="facebook"/>
                                            </FacebookShareButton>
                                            <WhatsappShareButton url={url}
                                            quote={"Hello pouvez-vous votez pour mon projet !!"}
                                            hashtag="#TCK">
                                            <img className="icon-instagram-1" src={instagram} alt="instagrame"/>
                                            </WhatsappShareButton>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                       
                    </div>
                    </div>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default DetailActu
