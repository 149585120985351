import { useContext, useEffect } from 'react'
import api from '../../Http/backend-base-url'
// import baseUrl from '../../Http/base-url'
import { DataContext } from '../store/GlobalState'

export const getDataOffers = async (url) => {
    const res = await fetch(`${api}${url}`, {
        method: 'GET'
    })
    const data = await res.json()
    return data
}
export const getDatas = async (url) => {
    const res = await fetch(`${api}${url}`, {
        method: 'GET'
    })
    const data = await res.json()
    return data
}

export const getDataBySlug = (array, slug) => {

    return array.find(x => x.id === slug)
}
export const getDataById = (array, id) => {

    return array.find(x => x.id === id)
}

export const getUserOwnProject = (array, id) => {

    return array.find(x => x.id === id)
}

export const getCommentairesByProject = (array, id) => {

    return array.filter(x => x.project === id)
}
export const getResponseQuestionFAQByProject = (array, id) => {

    return array.filter(x => x.faq === id)
}
export const getFAQByProject = (array, id) => {

    return array.filter(x => x.projet === id)
}

export const filterProjectByCategory = (array, id) => {

    return array.filter(x => x.category === id)
}

export const getUser = (array, id) => {

    return array.filter(x => x.id === id)
}
export const getDonationOwnProject = (array, id) => {

    return array.filter(x => x.projet.id === id)
}
export const getFileOwnProject = (array, id) => {

    return array.filter(x => x.project === id)
}
export const getMessageOwnProject = (array, id) => {

    return array.filter(x => x.id === id)
}
export const getUserStandProject = (array, id) => {

    return array.filter(x => x.user === id)
}
export const getNameProject = (array, id) => {

    var projet_title = array.filter(x => x.id === id)
    return projet_title;
}
export const getNameProjectInPaymentEtape = (array, id) => {

    return array.filter(x => x.projet.id === id)
}
export const getListProjectForStandardUser = (array, id) => {

    var user_project = array.filter(x => x.user === id)
    return user_project;
}

export const getListProjectDonantionForStandardUser = (array, id) => {

    var user_project_donation = array.filter(x => x.donateur.id === id)
    return user_project_donation;
}

export const groupBy=(list, keyGetter,projetName) =>{
    const map = new Map();
    list.filter(x => x.projet.title === projetName).forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

export const getNameOfCategoryByProject = (array, id) => {

    var name_project = array.filter(x => x.id === id)
    return name_project;
}

export const getData = async (url, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
    })
    const data = await res.json()
    return data
}

export const getDataByID = async (url, id, token) => {
    const res = await fetch(`${api}${url}/${id}/`, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${token}`
        }
    })

    const data = await res.json()
    return data
}

export const postData = async (url, post, token) => {
    console.log('url', api)
    const res = await fetch(`${api}${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}


export const putData = async (url, post, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}


export const patchData = async (url, post, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}


export const deleteData = async (url, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    })

    const data = await res.json()
    return data
}

