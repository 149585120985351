

import React, { useContext,useEffect, useState } from 'react'
import { DataContext } from '../../../redux/store/GlobalState';

var DomaineMobile = () => {
    const { state, dispatch } = useContext(DataContext)
    console.log('testtsts',state);
    let { category_projects_front } = state
    return (
        <div className=" col-sm-12 py-1 ">
            <div className="form-group">
                <label className="label-input pb-2">Choisir la domiane</label>
                <select
                placeholder="Type"
                name="domains"
                className="bloc-div form-control"
                >
                <option value="" >Choix du projet</option>
                {
                    category_projects_front ?
                    category_projects_front.map((item) => {
                        return (
                        <option key={'domains' + item.id} value={item.title}>
                            {item?.title}
                        </option>
                        )
                    })
                    :
                    <option>
                        Aucune Donnée
                    </option>
                }
                </select>
                
            </div>
        </div>
    );
}

export default DomaineMobile;






